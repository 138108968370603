
export interface GetAllResponce {
    Data: RemoteDevice[];
    Success:      boolean;
    Message:      string | null;
}

export interface GetByTimeResponse {
    Data:    DeviceHistory[];
    Success: boolean;
    Message: string;
}

export interface GetDrivingHistoryResponse {
    Data:    DrivingHistory[];
    Success: boolean;
    Message: string;
}


export interface DeviceHistory {
    Id:              number;
    Adi:             string;
    OperatorAdi:     null;
    SeriNo:          string;
    Online:          boolean;
    Hiz:             number;
    Enlem:           number;
    Boylam:          number;
    Yon:             number;
    YonDeg:          string;
    Kilometre:       number;
    CihazIndex:      number;
    CihazZamani:     string;
    SunucuZamani:    string;
    StatusModel:     StatusModel;
    IconDescription: IconDescription;
    IconName:        IconName;
    IconSize:        string;
    IconColor:       IconColor;
    Operator:        null;
    Sira:            number;
    Giris1:          null;
    Giris2:          null;
    Giris3:          null;
    KontakKapali:    null;
    AkuKesik:        null;
    Cikis1:          null;
    Cikis2:          null;
    Cikis3:          null;
    SicaklikLimit:   null;
    HizLimit:        null;
    YurtDisiGps:     null;
    DeltaMesafe:     null;
    CevrimDisiKayit: null;
    RTCHatali:       null;
    MotorDurdurma:   null;
    MaksimumDurma:   null;
    Rolanti:         null;
    GSensor:         null;
    Giris4:          null;
    Giris5:          null;
    HariciMudahele:  null;
}

export interface RemoteDevice {
    Id:           number;
    Durum:        boolean;
    Plaka:        string;
    Adi:          string;
    Gsm:          null | string;
    Marka:        null;
    Model:        null;
    JaseNo:       null;
    RuhsatSeriNo: null;
    Kilometre:    null;
    OperatorId:   number | null;
    Operator:     Operator | null;
    Cihazlar:     Cihazlar[] | null;
    NoktasAracs:  null;
}

export interface Cihazlar {
    Id:                   number;
    Durum:                boolean;
    CihazId:              string;
    SeriNo:               string;
    Model:                string;
    Gsm:                  string;
    AracId:               number;
    Arac:                 null;
    CihazDurums:          CihazDurum[];
    CihazKomuts:          null;
    DurakDatas:           null;
    ArcDurakDatas:        null;
    Alarmlar:             null;
    KayitKullanici:       null;
    KayitTarihi:          Date;
    GuncelleyenKullanici: null;
    GuncellenmeTarihi:    Date;
}

export interface CihazDurum {
    CihazDurumId: number;
    CihazId:      number;
    Cihaz:        null;
    CihazModel:   CihazModel;
    IpPort:       string;
    Online:       number;
    Network:      null | string;
    NetName:      null | string;
    Status:       Status;
    Speed:        number;
    gpsLng:       number;
    gpsLat:       number;
    Direction:    number;
    Mileage:      number;
    CihazIndex:   number;
    TempSensor1:  null;
    TempSensor2:  null;
    TempSensor3:  null;
    TempSensor4:  null;
    Protocol:     null;
    DiskType:     null;
    AudioCodec:   null;
    gpsTime:      string;
    serverTime:   string;
    StatusModel:  StatusModel;
    Icon:         Icon;
}

export enum CihazModel {
    M12 = "M12",
    M12A = "M12A",
    M40B = "M40B",
    M4A = "M4A",
    M6X = "M6X",
}

export interface Icon {
    Id:        number;
    Durum:     boolean;
    Tip:       number;
    Name:      IconDescription;
    IconName:  IconName;
    IconSize:  string;
    IconColor: IconColor;
}

export interface DrivingHistory {
    Adi: any
    BaslangicKonumAdres: any
    BaslangicZamani: string
    BitisKonumAdres: any
    BitisZamani: string
    CihazId: number
    Mesafe: number
    OrtalamaHiz: number
    OrtlamaYakitTuketimi: number
    Sure: any
    ToplamRolanti: number
    YakitTuketimi: number
    YuksekHiz: number
}




export enum IconColor {
    Blue = "blue",
    Gray = "gray",
    Green = "green",
    Red = "red",
}

export enum IconName {
    LocationArrow = "location-arrow",
    Parking = "parking",
}

export enum IconDescription {
    Hız5Rolanti = "Hız<5 Rolanti",
    Hız5Yolda = "Hız>5 (Yolda)",
    KontakKapalıMotorKapalı = "Kontak Kapalı (Motor Kapalı)",
    ÇevrımdışıKontakKapalı = "Çevrımdışı Kontak Kapalı",
}

export enum Status {
    The00008009 = "00008009",
    The00040003 = "00040003",
    The0004000B = "0004000B",
    The0004900B = "0004900B",
    The00140003 = "00140003",
    The0014800B = "0014800B",
}

export interface StatusModel {
    CihazId:         number;
    Giris1:          boolean;
    Giris2:          boolean;
    Giris3:          boolean;
    KontakKapali:    boolean;
    AkuKesik:        boolean;
    Cikis1:          boolean;
    Cikis2:          boolean;
    Cikis3:          boolean;
    SicaklikLimit:   boolean;
    HizLimit:        boolean;
    YurtDisiGps:     boolean;
    DeltaMesafe:     boolean;
    CevrimDisiKayit: boolean;
    RTCHatali:       boolean;
    MotorDurdurma:   boolean;
    MaksimumDurma:   boolean;
    Rolanti:         boolean;
    GSensor:         boolean;
    Giris4:          boolean;
    Giris5:          boolean;
    HariciMudahele:  boolean;
}

export interface Operator {
    OperatorId:    number;
    Adi:           string;
    Araclar:       null;
    Bolgeler:      null;
    OperatorUsers: OperatorUser[];
    Noktalar:      null;
}

export interface OperatorUser {
    Id:         number;
    UserId:     string;
    OperatorId: number;
    Operator:   null;
}

