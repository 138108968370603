import React, { Component, useEffect, useState, useRef } from 'react';

import GoogleMapReact from 'google-map-react';
import { DeviceHistory, DrivingHistory, GetByTimeResponse, GetDrivingHistoryResponse, IconColor, IconName } from '../../../types/devices';
import { useDevices } from '../../../providers/deviceProvider';
import CarVector from '../../../icons/cars/carVector';
import { url } from 'inspector';
import { io, Socket } from 'socket.io-client';
import { fitBounds } from 'google-map-react';
import axios from "axios";
import { useLogin } from '../../../providers/loginProvider';
import { Row, Col, Card, ListGroup, Tab, Button, Container, Collapse, Tabs, ProgressBar, Pagination } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';

import { GiRoad } from "react-icons/gi";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { AiOutlineWarning } from "react-icons/ai";

import { Doughnut, Pie, Chart } from "react-chartjs-2";

import './map.scss'
import moment from 'moment';

export interface LatLng {
  lat: number;
  lng: number;
}

interface LocalData {
  id: number;
  plaka: string;
  lat: number;
  lng: number;
  dir: number;
  icon: IconName;
  color: string;
  Hiz: number;
}

interface RedisData {
  location: LatLng;
  speed: number;
  heading: number;
  distance: number;
}

interface ISideBarState {
  list?: LocalData[],
  carActive?: LocalData,
  setCenter?: any,
  listVisibilty: string,
  detailOpen?: boolean,
  filteredColors?: IconColor[],
}

const CarMarkupComponent = ({ id, sideBarState, setSideBarState, car, lat, lng, setCenter, icon, text, color, rotation = 100 }: any) => {
  return (
    <div key={id} onClick={() => {
      setCenter({
        lat: lat,
        lng: lng
      })
      setSideBarState({
        ...sideBarState,
        carActive: car,
        setCenter: setCenter,
        listVisibilty: 'card-open'
      })
    }}
    >
      {icon === 'location-arrow' ? <CarVector color={color} transformDeg={rotation - 90} /> : <img src={require('../../../icons/cars/park.png').default} />
      }
      <div style={{ paddingTop: 5 }}>
        {text}
      </div>
    </div>
  )
}

function MapSideBar(props: any) {
  const { props: { setCenter, sideBarState, setSideBarState, drivingHistories, setDrivingHistories } } = props;
  const [selectedTimeToCar, setSelectedTimeToCar] = React.useState<DrivingHistory>({
    Adi: '',
    BaslangicKonumAdres: '',
    BaslangicZamani: '',
    BitisKonumAdres: '',
    BitisZamani: '',
    CihazId: 0,
    Mesafe: 0,
    OrtalamaHiz: 0,
    OrtlamaYakitTuketimi: 0,
    Sure: 0,
    ToplamRolanti: 0,
    YakitTuketimi: 0,
    YuksekHiz: 0
  })
  // list-closed kapalı
  // list-open
  // card-open
  const [chartData, setChartData] = useState(null);
  const [paginationData, setPaginationData] = useState({
    activePage: 1
  })
  const memo = (callback: any) => {
    const cache = new Map();
    return (...args: any) => {
      const selector = JSON.stringify(args);
      if (cache.has(selector)) return cache.get(selector);
      const value = callback(...args);
      cache.set(selector, value);
      return value;
    };
  };

  const { loginState } = useLogin();
  const [drivingLocations, setDrivingLocations] = React.useState<DeviceHistory[]>([])
  const memoizedAxiosPost = memo(axios.post);
  const handleCarClick = (item: any) => {
    setSideBarState({
      ...sideBarState,
      listVisibilty: 'card-open',
      carActive: item
    })
    setCenter({
      lat: item.lat,
      lng: item.lng
    })
  }

  useEffect(() => {
    if (sideBarState.list) {
      setChartData({
        labels: ['Park halinde', 'Hareketli', 'Duran'],
        datasets: [
          {
            label: '# of cars',
            data: [
              sideBarState.list.filter((item: LocalData) => item.color === IconColor.Red).length,
              sideBarState.list.filter((item: LocalData) => item.color === IconColor.Green).length,
              sideBarState.list.filter((item: LocalData) => item.color === IconColor.Blue).length
            ],
            backgroundColor: [
              'rgba(243, 4, 24, 0.8)',
              'rgba(36, 128, 24, 0.7)',
              'rgba(27, 14, 249, 0.8)',
            ],
            borderColor: [
              'rgba(243, 4, 24, 0.8)',
              'rgba(36, 128, 24, 0.7)',
              'rgba(27, 14, 249, 0.8)',
            ],
            borderWidth: 1,
          },
        ]
      })
    }
  }, [sideBarState])
  useEffect(() => {
    if (sideBarState.list) {
      setChartData({
        labels: ['Park halinde', 'Hareketli', 'Duran'],
        datasets: [
          {
            label: '# of cars',
            data: [
              sideBarState.list.filter((item: LocalData) => item.color === IconColor.Red).length,
              sideBarState.list.filter((item: LocalData) => item.color === IconColor.Green).length,
              sideBarState.list.filter((item: LocalData) => item.color === IconColor.Blue).length
            ],
            backgroundColor: [
              'rgba(243, 4, 24, 0.8)',
              'rgba(36, 128, 24, 0.7)',
              'rgba(27, 14, 249, 0.8)',
            ],
            borderColor: [
              'rgba(243, 4, 24, 0.8)',
              'rgba(36, 128, 24, 0.7)',
              'rgba(27, 14, 249, 0.8)',
            ],
            borderWidth: 1,
          },
        ]
      })
    }
    console.log('bbbbb', sideBarState)
  }, [sideBarState])

  function getFirstDate() {
    var today = new Date();
    var date = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0);
    console.log(date)
    console.log(today)
    console.log('------')
    return date;
  }

  useEffect(() => {
    console.log('Selected Car State:', sideBarState)
    console.log('old drivings:', drivingHistories)
    async function fetchMyApi() {

      await memoizedAxiosPost('https://api.ircom.com.tr/api/alarm/getlistbyseyahat',
        { CihazId: sideBarState.carActive.id, firstDate: getFirstDate(), lastDate: new Date() })
        .then((response: any) => response)
        .then((value: { data: { Success: any; Data: any; Message: any; }; }) => {

          if (value.data.Success) {
            console.log('getlistbyseyahat success');
            setDrivingHistories(value.data.Data)
            console.log('new2>', value.data.Data);
          }
          else {
            alert(value.data.Message)
          }

        }).catch((err: { response: any; }) => {
          console.log(err.response);
          // setErrorMessage(err.response)
        })
    }
    if (sideBarState.carActive) {
      fetchMyApi()
    }
  }, [sideBarState.carActive])
  const [legendReflesh, setLegendReflesh] = React.useState(false)
  async function asyncCall() {
    if (!legendReflesh) {
      setLegendReflesh(true)
      await resolveAfter();
    }
    // expected output: "resolved"
  }

  function resolveAfter() {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(document.getElementsByTagName('canvas')[0].click());
      }, 500);
    });
  }
  const handleChartClick = (dataSet: any, event: any) => {
    const chart = Chart.getChart(chartRef.current)
    // const clickedElements = chart!.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);
    const { current: { legend: { legendItems } } } = chartRef;

    // const { list } = sideBarState;
    const newFilter = []

    if (!legendItems[0].hidden) newFilter.push(IconColor.Red)
    if (!legendItems[1].hidden) newFilter.push(IconColor.Green)
    if (!legendItems[2].hidden) newFilter.push(IconColor.Blue)

    setSideBarState({
      ...sideBarState,
      filteredColors: newFilter
    })

    console.log(`New filter : ${newFilter}`)
    console.log('dataset', dataSet)
    console.log('event', event)
    console.log('legendItems', legendItems)
    console.log('chartRef', chartRef)

    // if (legendItems !== oldLegendItems)
    // { }
    if (!legendReflesh) {
      console.log('if 0 legendRefleshControl', legendReflesh)
      asyncCall()
    }
    else {
      setLegendReflesh(false)
    }
  }

  useEffect(() => {
    console.log(sideBarState.filteredColors)
  }, [sideBarState.filteredColors])

  const chartRef = useRef(null);

  if (sideBarState.list) {
    sideBarState.list.sort((a: any, b: any) => b.Hiz - a.Hiz)
  }

  const getLocationsByTime = () => {
    if (sideBarState.carActive) {
      console.log(sideBarState.carActive.id)
      axios.post('https://api.ircom.com.tr/api/konum/getlistbyseyahat',
        { CihazId: drivingHistories.CihazId, firstDate: "2021-11-09 00:00", lastDate: "2021-11-09 23:59" }, {
        headers: {
          'Authorization': `Bearer ${loginState}`,
          "Content-Type": "application/json"
        }
      })
        .then((response: any) => response)
        .then((value: { data: { Success: any; Data: any; Message: any; }; }) => {

          if (value.data.Success) {
            console.log('getlistbyseyahat success');
            setDrivingLocations(value.data.Data.filter((f: DeviceHistory) => { return f.Enlem; }),);
            console.log('new2>', value.data.Data);

            if (value.data.Data.length === 0) {
              alert(
                'Belirtilen Tarihler Arası Hareket Bulunamadı'
              );
            }
          }
          else {
            alert(value.data.Message)
          }

        }).catch((err: { response: any; }) => {
          console.log(err.response);
          // setErrorMessage(err.response)
        })
    }
  };

  React.useEffect(() => {
    return () => {
      getLocationsByTime()

    }
  }, [selectedTimeToCar])

  return (
    <div className="map-sidebar-list-container">
      <Card style={{ margin: '0px' }}>
        <Card.Header>
          <Card.Title style={{ width: '100%' }}>
            {
              sideBarState.listVisibilty === 'card-open' ? <Button style={{ width: '100%' }} onClick={() => setSideBarState({ ...sideBarState, listVisibilty: 'list-open' })}>Listeye dön</Button>
                : sideBarState.listVisibilty === 'list-closed' ? <Button onClick={() => setSideBarState({ ...sideBarState, listVisibilty: 'list-open' })}>Listeyi göster</Button>
                  : sideBarState.listVisibilty === 'list-open' ? <Button onClick={() => setSideBarState({ ...sideBarState, listVisibilty: 'list-closed' })}>Listeyi gizle</Button> : null
            }
          </Card.Title>
        </Card.Header>
        {/* <Card.Body> */}
        {
          sideBarState.listVisibilty === "list-open" ? (
            <Card.Body>
              <div className="doughnut-wrapper"
                style={{
                  width: "70%",
                  margin: '10px auto'
                }}

              >
                {
                  chartData !== null && <Doughnut ref={chartRef} data={chartData} getDatasetAtEvent={(dataSet, event) => handleChartClick(dataSet, event)} />
                }
              </div>
              <ListGroup>
                {
                  sideBarState.list.map((item: any) => {
                    // console.log(sideBarState.filteredColors, item)
                    if (sideBarState.filteredColors.includes(item.color)) {
                      return (
                        <ListGroup.Item onClick={() => handleCarClick(item)} action variant="light" key={item.id} className="map-sidebar-list-item">
                          {item.icon === 'location-arrow' ? <CarVector color={item.color} /> : <img src={require('../../../icons/cars/park.png').default} />}
                          <p>{item.plaka}</p>
                          <p>{item.Hiz} km/s</p>
                        </ListGroup.Item>
                      )
                    }
                  })
                }
              </ListGroup>
            </Card.Body>
          ) : sideBarState.listVisibilty === "card-open" && (
            <Card.Body>
              <Card.Title style={{ fontSize: '1.5em', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                {sideBarState.carActive.icon === 'location-arrow' ? <CarVector color={sideBarState.carActive.color} /> : <img src={require('../../../icons/cars/park.png').default} />}
                <p>{sideBarState.carActive.plaka}</p>
              </Card.Title>
              <Container>
                <Row>
                  <Col>
                    <p >{sideBarState.carActive.Hiz.toFixed(2)} <small>km/s</small></p>
                  </Col>
                  <Col>
                    <p>{sideBarState.carActive.lat.toFixed(4)} <small>lat</small></p>
                  </Col>

                </Row>
                <Row>
                  <Col>
                    <p>{sideBarState.carActive.lng.toFixed(4)} <small>lng</small></p>
                  </Col>
                  <Col>
                    <p>{sideBarState.carActive.lng.toFixed(4)} <small>lng</small></p>
                  </Col>
                </Row>
              </Container>

              {/* Detaylar */}

              <Button
                onClick={() => setSideBarState({ ...sideBarState, detailOpen: !sideBarState.detailOpen })}
                aria-controls="example-collapse-text"
                aria-expanded={sideBarState.detailOpen}
                // style={{"marginBottom":"10px"}}
                className="details-collapse-button"
              >
                Detaylar
              </Button>
              <Collapse in={sideBarState.detailOpen}>
                <div id="example-collapse-text" className="details">
                  {
                    ["Araç km'si",
                      "En Yakın Nokta",
                      "En Yakın İlgi Noktası",
                      "Koordinat",
                      "Sürücü",
                      "Araç Durumu",
                      "Araç Hızı",
                      "Son Hareket Tarihi"].map((breakpoint, idx) => {
                        return (
                          (
                            <ListGroup horizontal={true} key={idx}>
                              <ListGroup.Item><strong>{breakpoint}</strong></ListGroup.Item>
                              <ListGroup.Item>31811.9</ListGroup.Item>
                            </ListGroup>
                          )
                        )
                      })

                  }
                </div>
              </Collapse>

              {/* Tablar / iconlar */}
              <Tabs defaultActiveKey="trip" id="uncontrolled-tab-example" className="menu-tabs-container">
                <Tab eventKey="trip" title={<GiRoad size={28} />}>
                  <ul className="list-trips">
                    {console.log('drivingHistoriesdrivingHistories', drivingHistories)}
                    {drivingHistories.map((drivingHistory: DrivingHistory) => {
                      var date1 = moment(drivingHistory.BaslangicZamani);
                      var date2 = moment(drivingHistory.BitisZamani);
                      var diff = date2.diff(date1);
                      return (
                        <li onClick={() => { setSelectedTimeToCar(drivingHistory) }} key={drivingHistory.BaslangicZamani} className="list-trips-item">
                          <div className="trip-end">
                            <span>{drivingHistory.BitisZamani ? drivingHistory.BitisZamani.substring(drivingHistory.BitisZamani.length - 8, drivingHistory.BitisZamani.length - 3) : 'null'}</span>
                            <p style={{ minHeight: '55px', maxHeight: '55px' }} className="address">{drivingHistory.BitisKonumAdres ?? 'null'}
                            </p>
                          </div>
                          <div className="trip-info">
                            <i className="fa fa-arrow-up"></i>
                            <p className="address" style={{ paddingTop: '4px' }}> {diff}</p>
                          </div>

                          <div className="trip-start">
                            <span>{drivingHistory.BaslangicZamani ? drivingHistory.BaslangicZamani.substring(drivingHistory.BaslangicZamani.length - 8, drivingHistory.BaslangicZamani.length - 3) : 'null'}</span>
                            <p style={{ minHeight: '55px', maxHeight: '55px' }} className="address">{drivingHistory.BaslangicKonumAdres ?? 'null'}</p>
                          </div>

                        </li>)
                    })}
                    {drivingHistories.length === 0 ?
                      <Card.Title>
                        Aracın henüz günlük seyahati bulunmamaktadır
                      </Card.Title> : null

                    }



                  </ul>


                </Tab>
                <Tab eventKey="events" title={<AiOutlineWarning size={28} />}>
                  <ListGroup variant="flush" style={{ "border": "none" }}>
                    <ListGroup.Item>
                      <p>Cras justo odio</p>
                      <ProgressBar variant="dark" now={90} />
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <p>Cras justo odio</p>
                      <ProgressBar variant="dark" now={60} />
                    </ListGroup.Item><ListGroup.Item>
                      <p>Cras justo odio</p>
                      <ProgressBar variant="dark" now={40} />
                    </ListGroup.Item>
                  </ListGroup>
                </Tab>
                <Tab eventKey="reports" title={<HiOutlineDocumentReport size={28} />}>
                  <p>Geçmiş Konum Raporu</p>
                </Tab>
              </Tabs>


            </Card.Body>
          )
        }
        {/* </Card.Body> */}
      </Card>
    </div>
  )

}


function MapScreen() {
  const { devices, focusedDevice, setFocusedDevice } = useDevices();
  const map = React.useRef<GoogleMapReact>(null);

  const centerLoc: LatLng = {
    lat: 41.0141033333333,
    lng: 29.2207733333333
  };

  document.body.style.overflow = "scroll"

  const [socket, setSocket] = React.useState<Socket | null>(null);

  const [first, setFirst] = useState<boolean>(true);
  const [center, setCenter] = useState<LatLng | undefined>(centerLoc);
  const [follow, setFollow] = useState<number | null>(null);
  const [liveLocation, setLiveLocation] = useState<RedisData | null>(null);
  const [selectedDeviceId, setSelectedDeviceId] = useState<string | null>(null);
  const [carActive, setCarActive] = useState<LocalData | null>(null);
  const [locs2, setLocs2] = useState<LocalData[]>([]);
  const [drivingHistories, setDrivingHistories] = useState<DrivingHistory[] | null>([])


  const { loginState } = useLogin();
  const history = useHistory()



  const [sideBarState, setSideBarState] = useState<ISideBarState | null>({
    listVisibilty: 'list-closed',
    filteredColors: [
      IconColor.Red,
      IconColor.Blue,
      IconColor.Green
    ]
  })


  React.useEffect(() => {
    console.log('loginstate:', loginState)
    if (!loginState) {
      history.push('./login');
      return;
    }
  }, [])

  // React.useState(() => {
  //   const socket = io('wss://takipws.appac.io/', { transports: ['websocket'] });

  //   socket.on('connect_error', () => {
  //     console.log('Error connect');
  //     // revert to classic upgrade
  //     // socket.io.opts.transports = ['polling', 'websocket'];
  //   });

  //   socket.on('init', () => {
  //     console.log('Init From Server');
  //   });

  //   socket.on('update', (v: any) => {
  //     // if (v.deviceId === selectedDeviceId) {
  //     const data: RedisData = {
  //       location: { lat: v.location.lat, lng: v.location.lng },
  //       distance: v.distance,
  //       speed: v.speed,
  //       heading: v.heading,
  //     };
  //     setLiveLocation(data);

  //     // if (map.current) {
  //     //   map.current.animateCamera(
  //     //     {
  //     //       center: data.location,
  //     //     },
  //     //     { duration: 200 },
  //     //   );
  //     // }

  //     // } else {
  //     // setLiveLocation(null);
  //     // }
  //     console.log('Update Location', v, selectedDeviceId);
  //   });

  //   setSocket(socket);
  //   return () => {
  //     console.warn('Close Connection');
  //     socket.close();
  //   };
  // }, []);

  React.useEffect(() => {
    // console.log("Selected changed", focusedDevice);
    if (focusedDevice) {
      const deviceId = focusedDevice.Cihazlar[0]?.CihazId;
      if (deviceId) {
        if (selectedDeviceId !== deviceId) {
          setLiveLocation(null);
          if (socket) {
            socket.emit('unlisten', { device: selectedDeviceId });
          }
          setSelectedDeviceId(deviceId);
        }
        console.log('Focus ', focusedDevice.Cihazlar[0]?.CihazId);
        if (socket) {
          socket.emit('listen', { device: deviceId });
        }
      }
      // bottomSheetRef.current?.snapToIndex(1);
    } else {
      setLiveLocation(null);
      if (selectedDeviceId) {
        if (socket) {
          socket.emit('unlisten', { device: selectedDeviceId });
          setSelectedDeviceId(null);
        }
      }
      // bottomSheetRef.current?.snapToIndex(0);

    }
  }, [focusedDevice?.Id]);

  const centerAction = () => {
    // setCenter(true);
    setFocusedDevice(null);
    if (devices) {
      let l = devices.map(data => {
        return {
          plaka: data.Plaka,
          latitude: data.Cihazlar[0]?.CihazDurums[0]?.gpsLat,
          longitude: data.Cihazlar[0]?.CihazDurums[0]?.gpsLng,
          dir: data.Cihazlar[0]?.CihazDurums[0]?.Direction,
          icon: data.Cihazlar[0]?.CihazDurums[0]?.Icon.IconName,
          color: data.Cihazlar[0]?.CihazDurums[0]?.Icon.IconColor,
        };
      });
      l = l.filter(d => d.latitude);

      // if (map.current) {
      //   map.current.fitToCoordinates(l, {
      //     edgePadding: { top: 32, left: 32, right: 32, bottom: 32 },
      //     animated: true,
      //   });
      // }
    }
  };

  // callbacks
  const handleShedetChanges = React.useCallback(
    (index: number) => {
      console.log('handleSheetChanges', index);
      if (index === 0) {
        //   centerAction();
        setFocusedDevice(null);
      }
    },
    [focusedDevice, setFocusedDevice],
  );

  React.useEffect(() => {
    console.log('Devices Changed');
    if (devices.length === 0) {
      return;
    }

    if (focusedDevice === null) {
      setFollow(null);
    }

    let l: LatLng[] = [];
    const devices2 = devices.filter(d => {
      return (
        d.Cihazlar[0]?.CihazDurums[0]?.Online === 1 ||
        (focusedDevice !== null && d.Id === focusedDevice.Id)
      );
    });
    const locs2 = devices2.map(data => {
      // console.log('data', data)
      return {
        id: data.Id,
        plaka: data.Plaka,
        lat: data.Cihazlar[0]?.CihazDurums[0]?.gpsLat,
        lng: data.Cihazlar[0]?.CihazDurums[0]?.gpsLng,
        dir: data.Cihazlar[0]?.CihazDurums[0]?.Direction,
        icon: data.Cihazlar[0]?.CihazDurums[0]?.Icon.IconName,
        color: data.Cihazlar[0]?.CihazDurums[0]?.Icon.IconColor,
        Hiz: data.Cihazlar[0]?.CihazDurums[0]?.Speed,
      };
    })
    setLocs2(locs2);
    setSideBarState({
      ...sideBarState,
      list: locs2
    })

    if (map.current && first) {
      l = devices2.map(data => {
        return {
          plaka: data.Plaka,
          lat: data.Cihazlar[0]?.CihazDurums[0]?.gpsLat,
          lng: data.Cihazlar[0]?.CihazDurums[0]?.gpsLng,
          dir: data.Cihazlar[0]?.CihazDurums[0]?.Direction,
          icon: data.Cihazlar[0]?.CihazDurums[0]?.Icon.IconName,
          color: data.Cihazlar[0]?.CihazDurums[0]?.Icon.IconColor,
        };
      });
      l = l.filter(d => d.lat);

      setFirst(false);
    } else if (map.current && focusedDevice) {
      setCenter(undefined);

      const c = devices2.find(r => r.Id === focusedDevice.Id);
      if (!c) {
        return;
      }
      // console.log(c);
      console.log('Follow');
    } else {
      console.log('No Follow');
    }
  }, [first, devices, focusedDevice]);

  function createMapOptions(maps: any) {
    return {
      panControl: true,
      mapTypeControl: true,
      mapTypeControlOptions: {
        style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: maps.ControlPosition.BOTTOM_CENTER,
        mapTypeIds: [
          maps.MapTypeId.ROADMAP,
          maps.MapTypeId.SATELLITE,
          maps.MapTypeId.HYBRID
        ]
      },
      scrollwheel: true,
      mapId: 'f82d5ab2d74ffe0c',

      // styles: [{ stylers: [{ 'saturation': -100 }, { 'gamma': 0.8 }, { 'lightness': 4 }, { 'visibility': 'on' }] }]
    }
  }

  // function onClickMap({x, y, lat, lng, event}) 
  // { 
  //   console.log(x, y, lat, lng, event)
  // }
  const keys = { key: 'AIzaSyCzVNAyz_XmO1eH9Lfon7gwmj_hg5x3Fs4', language: 'tr', region: 'tr' }
  return (
    // Important! Always set the container height explicitly

    <div style={{ height: '95vh', width: '100%', position: 'relative' }}>

      <MapSideBar props={{ locs2, setCenter, carActive, sideBarState, setSideBarState, drivingHistories, setDrivingHistories }} />


      <GoogleMapReact
        bootstrapURLKeys={keys}
        center={center}
        defaultZoom={11}

        options={createMapOptions}
        // onClick={onClickMap}
        // layerTypes={['TrafficLayer', 'TransitLayer']}
        ref={map}

      >
        {locs2.map(d => {
          return d.lat && d.id ? (
            d.id === focusedDevice?.Id && liveLocation ? (
              <CarMarkupComponent
                key={d.id}
                lat={d.lat}
                lng={d.lng}
                icon={d.icon}
                text={d.plaka}
                color={d.color}
                rotation={d.dir}
                setCenter={setCenter}
                car={d}
                sideBarState={sideBarState}
                setSideBarState={setSideBarState}
              />
            ) : (

              <CarMarkupComponent
                key={d.id}
                lat={d.lat}
                lng={d.lng}
                icon={d.icon}
                text={d.plaka}
                color={d.color}
                rotation={d.dir}
                setCenter={setCenter}
                car={d}
                sideBarState={sideBarState}
                setSideBarState={setSideBarState}
              />
            )
          ) : null;
        })}

      </GoogleMapReact>
    </div>
  );

}

export default MapScreen;