import React, { Component } from 'react';
import './css/style.css';
import './css/sideBarstyle.css';
import Markup from './jsx/index';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { LoginProvider } from '../src/providers/loginProvider';
import { DeviceProvider } from '../src/providers/deviceProvider';


class App extends Component {

  render() {
    return (
      <div className="App">
        <LoginProvider>
          <DeviceProvider>
            <Markup />
            {/* <Lines /> */}
          </DeviceProvider>
        </LoginProvider>
      </div>
    );
  }
}

export default App;
