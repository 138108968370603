import React from 'react'
import { Row, Col, Card, Dropdown, Container, Button, Alert } from 'react-bootstrap'

import Select from 'react-select'
import { DeviceHistory, DrivingHistory, GetByTimeResponse, RemoteDevice } from '../../../../types/devices';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLogin } from '../../../../providers/loginProvider';
import { useHistory } from 'react-router-dom';
import tr from "date-fns/locale/tr"; // the locale you want
import moment from 'moment';


export default function DrivingDateSelection() {
    // const [startDate, endDate] = dateRange;
    const [startDate, setStartDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState(new Date());
    const [locations, setLocations] = React.useState<DeviceHistory[]>([]);
    // const [startDate, startTime]=drivingHistory ? drivingHistory.BaslangicZamani ? drivingHistory.BaslangicZamani.split('T') : ['',''] : ['','']
    // const [endDate, endTime]=drivingHistory ? drivingHistory.BitisZamani ? drivingHistory.BitisZamani.split('T') : ['',''] : ['','']
    const axios = require('axios');
    const { loginState } = useLogin();
    const [carsData, setCarsData] = React.useState([])
    const history = useHistory()
    const [selectedOption, setSelectedOption] = React.useState<RemoteDevice>(null);

    const CustomDateTime = (props: { title: string, date: Date, setDate: any }) => {


        type RefType ={
            value?: any; 
            onClick?:any; 
            title?:any;
        }
        const ExampleCustomInput = React.forwardRef<any,RefType>((forwardProps, ref) => (
            <div className='col-md-12 col-sm-12'>
            <p>{props.title}</p>
            <button className="btn btn btn-outline-primary mb-2 mr-2 col-12" onClick={forwardProps.onClick} ref={ref}>
                {forwardProps.value}
            </button>
            </div>
        ));

        return (
            <DatePicker
             
                customInput={<ExampleCustomInput />}
                dayClassName={date => ''}
                selected={props.date}
                onChange={(date:Date) => props.setDate(date)}
                locale={tr}
                showTimeSelect
                timeFormat="p"
                timeIntervals={15}
                dateFormat="Pp"
            />

        );
    };
    React.useEffect(() => {
        console.log('loginstate:', loginState)
        if (!loginState) {
            history.push('./login');
            return;
        }

        (async () => {
            axios.get('https://api.ircom.com.tr/api/arac/getall', {
                headers: {
                    'Authorization': `Bearer ${loginState}`
                }
            })
                .then(function (response: { data: { Data: React.SetStateAction<any[]>; }; }) {
                    // handle success
                    console.log(response.data.Data);
                    setCarsData(response.data.Data)
                })
                .catch(function (error: { response: any; }) {
                    // handle error
                    console.log(error.response);

                    // console.log(error.response.data.message);
                })
                .then(function () {
                    // always executed
                });
        })();
    }, [])


    function msToTime(ms: number) {
        let seconds = (ms / 1000).toFixed(1);
        let minutes = (ms / (1000 * 60)).toFixed(1);
        let hours = (ms / (1000 * 60 * 60)).toFixed(1);
        let days = (ms / (1000 * 60 * 60 * 24)).toFixed(1);
        if (Number(seconds) < 60) return seconds + " Saniye.";
        else if (Number(minutes) < 60) return minutes + " Dakika";
        else if (Number(hours) < 24) return hours + " Saat";
        else return days + " Gün"
      }

    const doSearch = (firstDate= startDate, lastDate= endDate ) => {
  
        let newFirstDate=moment(firstDate).format('YYYY-MM-DD HH:mm:ss')
        let newLastDate=moment(lastDate).format('YYYY-MM-DD HH:mm:ss')
      console.log(selectedOption.Plaka,selectedOption.Id ,newFirstDate,
        newLastDate,)
  

      console.log('Hello');
      const raw = JSON.stringify({
        CihazId: selectedOption.Id,
        newFirstDate,
        newLastDate,
      });
  

      axios.post('https://api.ircom.com.tr/api/konum/getbytime',
      { CihazId: selectedOption.Id, firstDate: newFirstDate, lastDate: newLastDate}, {
      headers: {
        'Authorization': `Bearer ${loginState}`,
        "Content-Type": "application/json"
      }
    })
      .then((response: any) => response)
      .then((value: { data: { Success: any; Data: any; Message: any; }; }) => {

        if (value.data.Success) {
          console.log('getlistbyseyahat success');
      
           setLocations(
             value.data.Data.filter((f: { Enlem: any; }) => {
               return f.Enlem;
             }))

          if (value.data.Data.length === 0) {
          alert('Belirtilen Tarihler Arası Hareket Bulunamadı')
          }


        }
        else {
            console.log('getlistbyseyahat error');

          alert(value.data.Message)
        }

      }).catch((err: { response: any; }) => {
        alert('Belirtilen Tarihler Arası Hareket Bulunamadı')
        console.log(err.response);
        // setErrorMessage(err.response)
      })
      
    //   fetch('http://api.ircom.com.tr/api/konum/getbytime', {
    //     method: 'POST',
    //     headers: {
    //       Authorization: `Bearer ${loginState!}`,
    //       'Content-Type': 'application/json',
    //     },
    //     body: raw,
    //     redirect: 'manual',
    //   })
    //     .then(response => response.json())
    //     .then((result: GetByTimeResponse) => {
    //       if (result.Success) {
    //          console.log("Done", result);
      
    //         setLocations(
    //           result.Data.filter(f => {
    //             return f.Enlem;
    //           }))
    //         if (result.Data.length === 0) {
    //         alert('Belirtilen Tarihler Arası Hareket Bulunamadı')
    //         }
  
  
    //       } else {
    //         alert(result.Message);
    //       }
    //     })
    //     .catch(error => {
      
    //         alert('Belirtilen Tarihler Arası Hareket Bulunamadı')
    //       console.log('error', error, newFirstDate, newLastDate, selectedOption);
    //     });
    };

    return (

        <Card style={{ minHeight: window.innerHeight + 'px', maxHeight: window.innerHeight+'px'}} className="p-4">

            <Card.Title>Geçmiş Konum Raporu</Card.Title>

                <Card.Text>
                    <p>Araç Plakası</p>
                    <Select options={carsData} getOptionValue={opt => opt.Plaka} onChange={setSelectedOption} getOptionLabel={opt => opt.Plaka} />
                </Card.Text>
                <Card.Text>

                        
                        <CustomDateTime date={startDate} setDate={setStartDate} title='Başlangıç Tarihi' />

                        
                        <CustomDateTime date={endDate} setDate={setEndDate}  title='Bitiş Tarihi Tarihi'/>
                        
                
                </Card.Text>

        
<Button onClick={()=>{doSearch(startDate, endDate)}}>Başlat</Button>


        </Card >
 
    )
}
