import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap'



function Eroor404() {

    return (
        <>
            <div className="row justify-content-center mt-5 align-items-center error-page">
                <div className="col-md-6">
                    <div className="form-input-content text-center">
                        <div className="mb-5">
                            <Link className="btn btn-primary" to={'../'}>Back to Home</Link>
                        </div>
                        <h1 className="error-text font-weight-bold">404</h1>
                        <h4 className="mt-4"><i className="fa fa-exclamation-triangle text-warning"></i>Aradığınız sayfa bulunamadı!
</h4>
                        <p>Adresi yanlış yazmış olabilirsiniz veya sayfa taşınmış olabilir.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Eroor404;