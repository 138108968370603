import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, ListGroup, Tab, Accordion, NavLink } from 'react-bootstrap'
import Dropdown from 'react-bootstrap/Dropdown'
import HizAsimRaporu from '../../component/reportsPage/hizAsimRaporu';
import axios from 'axios';
import { useLogin } from '../../../providers/loginProvider';
import { useHistory } from 'react-router-dom';
import { GetAllResponce, RemoteDevice } from '../../../types/devices';
import { GecmisKonumRaporu } from '../../component/reportsPage/gecmisKonumRaporu';
import Sidebar from '../../layout/sidebar';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import ReportsSidebar from '../../component/reportsPage/reportsSidebar';


function Reports() {
    const [selectedPage, setSelectedPage] = React.useState<number | null>();
    const axios = require('axios');
    const { loginState } = useLogin();
    const [carsData, setCarsData] = React.useState<RemoteDevice[]>([])
    const history = useHistory()

    React.useEffect(() => {
        console.log('loginstate:', loginState)
        if (!loginState) {
            history.push('./login');
            return;
        }

        (async () => {
            axios.get('https://api.ircom.com.tr/api/arac/getall', {
                headers: {
                    'Authorization': `Bearer ${loginState}`
                }
            })
                .then(function (response: { data: GetAllResponce }) {
                    // handle success
                    console.log(response.data.Data);
                    setCarsData(response.data.Data)
                })
                .catch(function (error: { response: any; }) {
                    // handle error
                    console.log(error.response);

                    // console.log(error.response.data.message);
                })
                .then(function () {
                    // always executed
                });
        })();
    }, [])

    const [sideBarWidth, setSidebarWidth] = React.useState(null)
    const aaa = document.querySelector('#reports-wrapper');

    const hamburgerMenuOnOff = () => {

        return (
            setSidebarWidth(aaa.classList.contains("menu-toggle2"))
        )
    }

    return (
        <>
            <div id='reports-wrapper'>
                <Col xl={12} >
                    
                        <Card.Header>
                            <Card.Title >

                                <div className="report-sidebar-control wave-effect wave-effect-x ">
                                    <Row className='pl-1'>
                                        <div onClick={() => hamburgerMenuOnOff()}  className="hamburger">
                                            <span className="toggle-icon"><i style={sideBarWidth ? {transform: "rotate(90deg)", transition:'all 0.4s ease'} : {transition:'all 0.4s ease'}} className={"fa fa-arrow-right"}></i> </span>
                                
                                        </div>
                                    
                                    </Row>
                                </div>

                            </Card.Title>
                        </Card.Header>

                        <Tab.Container id="list-group-tabs-example" defaultActiveKey="#link2">
                            <BrowserRouter>
                                <Row >
                                    <div id={'sidebardiv'} style={{ width: sideBarWidth ? '3.1875rem' : '17.1875rem', transition: 'width .2s' }} >
                                        <ReportsSidebar />
                                    </div>

                                    {/* <Accordion >
                                        <Card style={{ marginBottom: 0, paddingBottom: 5 }}>
                                            <Accordion.Toggle as={Card.Header} eventKey="0">
                                                Konum Raporları
                                            </Accordion.Toggle>

                                            <Accordion.Collapse eventKey="0">
                                                <Card.Body >
                                                    <NavLink id="nav-link" onClick={() => setSelectedPage(1)}>Geçmiş Konum Raporu</NavLink>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                        <Card>
                                            <Accordion.Toggle as={Card.Header} eventKey="1">
                                                Denetim Raporları
                                            </Accordion.Toggle>

                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body >
                                                    <NavLink id="nav-link" onClick={() => setSelectedPage(2)}>Hız Aşım Raporu</NavLink>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion> */}


                                    <Col style={{ zIndex: 0, minHeight: window.innerHeight - 50 + 'px', width: '%50' }}>
                                        <Switch>
                                            <Route path='/test1' component={GecmisKonumRaporu} />
                                            <Route path='/test2' component={HizAsimRaporu} />

                                        </Switch>
                                        {/* <DatePicker
                                        selectsRange={true}
                                        startDate={startDate}
                                        endDate={endDate}
                                        onChange={(update: any) => {
                                            setDateRange(update);
                                        }}
                                        isClearable={true}
                                    /> */}
                                        {/* {selectedPage == 1 ?
                                    <GecmisKonumRaporu carsData/> :
                                    <HizAsimRaporu/>
                                    
                                    } */}
                                        {/* <Tab.Pane eventKey="#link1">
                                        

                                        </Tab.Pane>
                                        <Tab.Pane eventKey="#link2">
                                            I grant thou wert not married to my Muse, And therefore mayst without attaint o'erlook The dedicated words which writers use Of their fair subject, blessing every book. Thou art as fair in knowledge as in hue, Finding thy worth a limit past my praise; And therefore art enforced to seek anew Some fresher stamp of the time-bettering days. And do so, love; yet when they have devis'd, What strained touches rhetoric can lend,
                                        </Tab.Pane> */}

                                    </Col>
                                </Row>
                            </BrowserRouter>

                        </Tab.Container>

                    
                </Col>
                {/* <Col xl={12}>
                    <Card>
                        <Card.Header>
                            <Card.Title>Raporlar</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Tab.Container id="list-group-tabs-example" defaultActiveKey="#link1">
                                <Row>
                                    <Col sm={2}>
                                        <ListGroup>
                                            <ListGroup.Item action href="#link1">
                                                Link 1
                                            </ListGroup.Item>
                                            <ListGroup.Item action href="#link2">
                                                Link 2
                                            </ListGroup.Item>
                                        </ListGroup>
                                        
                                    </Col>
                                    <Col sm={8}>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="#link1">
                                                I grant thou wert not married to my Muse, And therefore mayst without attaint o'erlook The dedicated words which writers use Of their fair subject, blessing every book. Thou art as fair in knowledge as in hue, Finding thy worth a limit past my praise; And therefore art enforced to seek anew Some fresher stamp of the time-bettering days. And do so, love; yet when they have devis'd, What strained touches rhetoric can lend,
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="#link2">
                                                I grant thou wert not married to my Muse, And therefore mayst without attaint o'erlook The dedicated words which writers use Of their fair subject, blessing every book. Thou art as fair in knowledge as in hue, Finding thy worth a limit past my praise; And therefore art enforced to seek anew Some fresher stamp of the time-bettering days. And do so, love; yet when they have devis'd, What strained touches rhetoric can lend,
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </Card.Body>
                    </Card>
                </Col> */}
            </div>
        </>
    )
}

export default Reports;