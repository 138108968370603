import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Register from './pages/loginPages/register';
import ForgotPassword from './pages/loginPages/forgotPassword';
import RegisterationSuccess from './pages/loginPages/registerationSuccess'

import { useHistory } from 'react-router-dom';

import Login from './pages/loginPages/login';
import MainPage from './component/mainPage';
import Eroor400 from './pages/400';
import Eroor403 from './pages/403';
import Eroor404 from './pages/404';
import Eroor500 from './pages/500';
import Eroor503 from './pages/503';
import axios from 'axios';



export default function Markup() {
        return (
            <>
                {/* <BrowserRouter basename={'/appac'}> */}

                <BrowserRouter>
                    <Switch>
                        <Route path='/login' component={Login} />
                        <Route path='/register' component={Register} />
                        <Route path='/forgotpassword' component={ForgotPassword} />
                        <Route path='/registeration-success' component={RegisterationSuccess} />
                        <Route path='/400' component={Eroor400} />
                        <Route path='/403' component={Eroor403} />
                        <Route path='/404' component={Eroor404} />
                        <Route path='/500' component={Eroor500} />
                        <Route path='/503' component={Eroor503} />
                        <Route path='/' component={MainPage} />
                        <Route component={Eroor404} />
                        {/* <Route path='/' exact component={localStorage.getItem('loginToken') ===} /> */}
                    </Switch>
                </BrowserRouter>
            </>
            
        );
    
}

