/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import { LoginObject } from '../types/login';

type LoginContextType = {
  loginState: LoginObject | false | null;
  login: (data: LoginObject) => void;
  logout: () => void;
};

const LoginContext = React.createContext<LoginContextType>({
  loginState: null,
  login: () => {},
  logout: () => {},
});

export function LoginProvider({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) {
  const [loginState, setLoginState] = React.useState<LoginObject | false | null>(localStorage.getItem('loginToken') === null ? false : localStorage.getItem('loginToken'));

  React.useEffect(() => {
   const data = localStorage.getItem('loginToken')
    if(data === null){
      console.log('data null')
        setLoginState(false)
    }
    else {
      console.log('data not null',data)

        setLoginState(JSON.parse(data))
    }

    // SecureStore.getItemAsync('login_data')
    //   .then(data => {
    //     if (data === null) {
    //       setLoginState(false);
    //     } else {
    //       setLoginState(JSON.parse(data));
    //     }
    //   })
    //   .catch(() => {
    //     Alert.alert('Uyarı', 'Telefonunuz bu uygulamayı desteklemiyor', [
    //       { text: 'tamam' },
    //     ]);
    //   });
  }, []);

  React.useEffect(() => {
    if (loginState === null || loginState === false) {
      return () => {};
    }

  }, [loginState]);

  const login = (data: LoginObject) => {
    localStorage.setItem('loginToken', JSON.stringify(data))
    setLoginState(data);
    console.log(data)
  };

  const logout = () => {
    localStorage.removeItem('loginToken')
    setLoginState(false);
  };
  return (
    <LoginContext.Provider value={{ loginState, login, logout }}>
      {children}
    </LoginContext.Provider>
  );
}

export const useLogin = () => React.useContext(LoginContext);
