import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PasswordChecklist from "react-password-checklist"
import axios from "axios";
import { useHistory } from 'react-router-dom';

function Register() {
    const [name, setName] = useState("")
    const [surname, setSurname] = useState("")
    const [email, setEmail] = useState("")
    const [alertMessage, setAlertMessage] = React.useState(null)

    const [password, setPassword] = useState("")
    const [passwordAgain, setPasswordAgain] = useState("")
    const [successPassword, setSuccessPassword] = useState(false)
    const history = useHistory()

    const registerControl = (name,surname,email,password) => {
        console.log(name,surname,email,password);
        if (successPassword) {
        axios.post(`https://api.ircom.com.tr/api/auth/register`,
            { firstName:name,lastName:surname, Password:password, Email:email })
            .then(response => response)
            .then((value) => {
                console.log(value);
                setAlertMessage({status:value.request.status, message:value.data.Data.Token})
                localStorage.setItem('loginToken', value.data.token)
                 history.push({pathname:'./registeration-success', state: { email: email }})
            }).catch(err => {
                console.log(err.response);
                setAlertMessage({status:err.response.request.status, message:err.response.data.Message})

            })
        }
        else {
            setAlertMessage({status:500, message:'Lütfen güvenli parola için gerekli uyarıları tamamlayın'})
        }
    }

    return (
        <>
            <div className="row justify-content-center mt-4 mb-2 align-items-center">
                <div className="col-md-6">
                    <div className="authincation-content">
                        <div className="row no-gutters">
                            <div className="col-xl-12">
                                <div className="auth-form">
                                    <h4 className="text-center mb-4">Kayıt Ol</h4>
                                    <form>
                                        <div className="form-group">
                                            <label><strong>Ad</strong></label>
                                            <input type="text" onChange={e => setName(e.target.value)} className="form-control" placeholder="" />
                                        </div>
                                        <div className="form-group">
                                            <label><strong>Soyad</strong></label>
                                            <input type="text" className="form-control" onChange={e => setSurname(e.target.value)} placeholder="" />
                                        </div>
                                        {/* <div className="form-group">
                                            <label><strong>Telefon</strong></label>
                                            <input type="tel" className="form-control" onChange={e => setPhoneNumber(e.target.value)} placeholder="" />
                                        </div> */}
                                        <div className="form-group">
                                            <label><strong>Email</strong></label>
                                            <input type="email" className="form-control" onChange={e => setEmail(e.target.value)} placeholder="hello@example.com" />
                                        </div>
                                        <div className="form-group">
                                            <label><strong>Parola</strong></label>
                                            <input type="password" onChange={e => setPassword(e.target.value)} className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <label><strong>Parolanızı Tekrar Girin</strong></label>
                                            <input type="password" onChange={e => setPasswordAgain(e.target.value)} className="form-control" />
                                        </div>
                                        <PasswordChecklist
                                            rules={["minLength", "specialChar", "number", "capital", "match"]}
                                            minLength={8}
                                            value={password}
                                            valueAgain={passwordAgain}
                                            messages={{
                                                minLength: "Şifre 8 karakterden uzun.",
                                                specialChar: "Şifreniz özel karakter içeriyor",
                                                number: "Şifrenin bir numarası var.",
                                                capital: "Şifrede büyük harf var.",
                                                match: "Şifreler eşleşiyor.",
                                            }}
                                            onChange={(isValid) => {
                                                setSuccessPassword(isValid)
                                                console.log(isValid)
                                            }}

                                        />
                                        {!alertMessage ? null : <div className={`alert ${alertMessage.status===200 ? 'alert-success' : 'alert-danger' }`} role="alert">
                                            {alertMessage.message}</div>
                                            }
                                        <div className="text-center mt-4">
                                            <button type="button" onClick={()=>registerControl(name,surname,email,password)} className="btn btn-primary btn-block">Gönder</button>
                                        </div>
                                    </form>
                                    <div className="new-account mt-3">
                                        <p>Zaten hesabınız var mı? <Link className="text-primary" to={'./login'}>Giriş Yap</Link></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Register;