import React from 'react'
import { Row, Col, Card, Dropdown } from 'react-bootstrap'
import CustomDropDownList from '../form/dropDownList'
import CustomDropdown from '../ui/dropdown-custom'
import Select from 'react-select'
import { RemoteDevice } from '../../../types/devices';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLogin } from '../../../providers/loginProvider';
import { useHistory } from 'react-router-dom';
import tr from "date-fns/locale/tr"; // the locale you want


export function GecmisKonumRaporu() {
    const [startDate, setStartDate] = React.useState(new Date());
    // const [startDate, endDate] = dateRange;

    const axios = require('axios');
    const { loginState } = useLogin();
    const [carsData, setCarsData] = React.useState([])
    const history = useHistory()
    const CustomDateTime = (props) => {
        const [startDate, setStartDate] = React.useState(new Date());
        const ExampleCustomInput = React.forwardRef(({ value, onClick, title }, ref) => (
            <div className='col-md-6 col-sm-12'>
            <p>{props.title}</p>
            <button className="btn btn-primary mb-2 mr-2" onClick={onClick} ref={ref}>
                {value}
            </button>
            </div>
        ));
        return (
            <DatePicker
                customInput={<ExampleCustomInput />}
                dayClassName_
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                locale={tr}
                showTimeSelect
                timeFormat="p"
                timeIntervals={15}
                dateFormat="Pp"
            />

        );
    };
    React.useEffect(() => {
        console.log('loginstate:', loginState)
        if (!loginState) {
            history.push('./login');
            return;
        }

        (async () => {
            axios.get('https://api.ircom.com.tr/api/arac/getall', {
                headers: {
                    'Authorization': `Bearer ${loginState}`
                }
            })
                .then(function (response) {
                    // handle success
                    console.log(response.data.Data);
                    setCarsData(response.data.Data)
                })
                .catch(function (error) {
                    // handle error
                    console.log(error.response);

                    // console.log(error.response.data.message);
                })
                .then(function () {
                    // always executed
                });
        })();
    }, [])

    return (

        <Card className="p-4">
            <Card.Title>Geçmiş Konum Raporu</Card.Title>

            <Card className="p-4">
                <Card.Text>
                    <p>Araç Plakası</p>
                    <Select options={carsData} getOptionValue={opt => opt.Plaka} getOptionLabel={opt => opt.Plaka} />
                </Card.Text>
                <Card.Text className=''>
       <div className='row'>    
                        <CustomDateTime title='Başlangıç Tarihi'/>   
                        <CustomDateTime title='Bitiş Tarihi Tarihi'/>
                        </div>
                </Card.Text>

            </Card >



        </Card >

    )
}
