import React from "react";

interface Props {
  width?:number
  height?: number;
  color?: string
  transformDeg?: number
}


function CarVector(props: Props) {
  const {color,transformDeg} = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40.153"
      height="19.095"
      viewBox="0 0 40.153 19.095"
      // style={transformDeg ? { 
      //   rotate: `${transformDeg}deg`}:{}}
      style={{transform: `rotate(${transformDeg}deg)`}}
    >
      <g transform="rotate(90 125.883 -30.078)">
        <g data-name="Group 10501" transform="translate(155.961 55.651)">
          <path
            fill="#45474a"
            d="M159.468 66.3a18.633 18.633 0 01-.187-2.629V61.8a6.153 6.153 0 016.153-6.153h5.251a6.153 6.153 0 016.153 6.153v1.871a18.6 18.6 0 01-.187 2.629l-.368 2.58a18.639 18.639 0 00-.185 2.887l.149 10.716a6.565 6.565 0 00.295 1.856 6.565 6.565 0 01.3 1.947v4.756a4.758 4.758 0 01-4.758 4.758h-8.042a4.758 4.758 0 01-4.758-4.758V86.29a6.565 6.565 0 01.3-1.947 6.559 6.559 0 00.295-1.856l.149-10.716a18.611 18.611 0 00-.185-2.887z"
            data-name="Path 4121"
            transform="translate(-158.512 -55.651)"
          ></path>
          <path
          fill={color ?? 'red'}
          d="M177.81 70.587a17.572 17.572 0 00.176-2.48v-1.766a5.807 5.807 0 00-5.806-5.807h-4.956a5.807 5.807 0 00-5.807 5.807v1.766a17.572 17.572 0 00.176 2.48l.347 2.435a17.577 17.577 0 01.174 2.724l-.14 10.113a6.2 6.2 0 01-.278 1.752 6.189 6.189 0 00-.279 1.837v4.488a4.49 4.49 0 004.49 4.49h7.593a4.49 4.49 0 004.49-4.49v-4.488a6.189 6.189 0 00-.279-1.837 6.2 6.2 0 01-.278-1.752l-.14-10.113a17.577 17.577 0 01.174-2.724z"
            data-name="Path 4122"
            transform="translate(-160.154 -59.404)"
          ></path>
          <path
            fill="#f0f0f0"
            d="M164.231 65c-.5-.732 1.664-2.163 2.3-1.664s-1.884 2.271-2.3 1.664z"
            data-name="Path 4123"
            transform="translate(-162.26 -61.476)"
          ></path>
          <path
            fill="#45474a"
            d="M157.2 107.28a5.615 5.615 0 00-1.171 1.026c-.166.333 0 .638.111.61s1.414-.582 1.414-.582z"
            data-name="Path 4124"
            transform="translate(-155.961 -95.329)"
          ></path>
          <path
            fill="#45474a"
            d="M164.123 114.789a88.756 88.756 0 00-.067-4.42 40.233 40.233 0 00-.8-6.323c-.21 4.178-.307 7.57-.333 10.314.392.181.794.265 1.2.429z"
            data-name="Path 4125"
            transform="translate(-161.308 -92.843)"
          ></path>
          <path
            fill="#45474a"
            d="M162.892 150.014c-.064 8.468.558 10.572.558 10.572a61.564 61.564 0 00.649-10.169c-.399-.124-.799-.317-1.207-.403z"
            data-name="Path 4126"
            transform="translate(-161.284 -128.17)"
          ></path>
          <path
            fill="#45474a"
            d="M226.281 114.789c-.01-1.785.015-3.322.067-4.42a40.231 40.231 0 01.8-6.323c.21 4.178.307 7.57.333 10.314-.392.181-.795.265-1.2.429z"
            data-name="Path 4127"
            transform="translate(-210.001 -92.843)"
          ></path>
          <path
            fill="#45474a"
            d="M227.5 150.014c.064 8.468-.558 10.572-.558 10.572a61.564 61.564 0 01-.649-10.169c.395-.124.797-.317 1.207-.403z"
            data-name="Path 4128"
            transform="translate(-210.01 -128.17)"
          ></path>
          <path
            fill="#ff4b4b"
            d="M167.506 220.36c-.112.235-.67.2-1.247-.07s-.954-.686-.843-.922.67-.2 1.247.07.955.687.843.922z"
            data-name="Path 4129"
            transform="translate(-163.213 -181.349)"
          ></path>
          <path
            fill="#f0f0f0"
            d="M221.95 65c.5-.732-1.664-2.163-2.3-1.664s1.884 2.271 2.3 1.664z"
            data-name="Path 4130"
            transform="translate(-204.826 -61.476)"
          ></path>
          <path
            fill="#45474a"
            d="M231.91 107.28a5.615 5.615 0 011.171 1.026c.166.333 0 .638-.111.61s-1.414-.582-1.414-.582z"
            data-name="Path 4131"
            transform="translate(-214.057 -95.329)"
          ></path>
          <path
            fill="#ff4b4b"
            d="M219.837 220.36c.112.235.67.2 1.247-.07s.954-.686.843-.922-.67-.2-1.247.07-.955.687-.843.922z"
            data-name="Path 4132"
            transform="translate(-205.036 -181.349)"
          ></path>
          <path
            fill="#45474a"
            d="M173.619 108.543a16.167 16.167 0 005.912-.8v6.128s-1.509 1.691-5.912 1.691c-4.4 0-5.912-1.691-5.912-1.691v-6.128a16.168 16.168 0 005.912.8zm0-23.9c-5.671 0-7.1 3.106-7.1 3.106l.759 6.1a29.386 29.386 0 0112.691 0l.759-6.1s-1.438-3.109-7.109-3.109z"
            data-name="Path 4133"
            transform="translate(-164.071 -77.929)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default CarVector;
