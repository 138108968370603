import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
import { Modal, Button, Tab, Nav } from 'react-bootstrap'


function EmailInbox() {
    const [lgShow, setLgShow] = useState(false);

    const handleClose = () => setLgShow(false);
    const handleShow = () => setLgShow(true);

    return (
        <>


            <Modal onHide={handleClose} animation={true} size="lg"
                show={lgShow}
                onHide={() => setLgShow(false)}>
                <div className="compose-content p-3">
                    <form action="#">
                        <div className="form-group">
                            <input type="text" className="form-control bg-transparent" placeholder=" To:" />
                        </div>
                        <div className="form-group">
                            <input type="text" className="form-control bg-transparent" placeholder=" Subject:" />
                        </div>
                        <div className="form-group">
                            <textarea id="email-compose-editor" className="textarea_editor form-control bg-transparent" rows="15" placeholder="Enter text ..."></textarea>
                        </div>
                    </form>
                    <h5 className="mb-4"><i className="fa fa-paperclip"></i> Attatchment</h5>
                    <form action="#" className="d-flex flex-column align-items-center justify-content-center">
                        <div className="fallback w-100">
                            <input type="file" className="dropify" data-default-file="" />
                        </div>
                    </form>

                    <div className="text-left mt-4">
                        <button className="btn btn-primary btn-sl-sm mr-3" type="button"><span
                            className="mr-2"><i className="fa fa-paper-plane"></i></span> Send</button>
                    </div>
                </div>
            </Modal>

            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body p-0">
                            {/* <MailBox /> */}

                            <Tab.Container defaultActiveKey="inbox">
                                <div className="email-app">
                                    <div className="left-part bg-white ">
                                        <div>
                                            <div className="p-3" onClick={handleShow}><span className="btn btn-danger d-block text-white">Compose</span></div>
                                            <div className="divider"></div>
                                            <ul className="email-filters list-group">
                                                <li className="list-group-item">
                                                    <Nav.Link eventKey="inbox" className="p-0">
                                                        <span className="list-group-item-action ">
                                                            <i className="fa fa-inbox mr-2"></i> Inbox
                                                        </span>
                                                    </Nav.Link>
                                                </li>
                                                <li className="list-group-item">
                                                    <Nav.Link eventKey="sent" className="p-0">
                                                        <span className="list-group-item-action ">
                                                            <i className="fa fa-paper-plane mr-2"></i> Sent
                                                            </span>
                                                    </Nav.Link>
                                                </li>
                                                <li className="list-group-item">
                                                    <Nav.Link eventKey="drafts" className="p-0">
                                                        <span className="list-group-item-action ">
                                                            <i className="fa fa-file mr-2"></i> Drafts
                                                        </span>
                                                    </Nav.Link>
                                                </li>
                                                <li className="list-group-item">
                                                    <Nav.Link eventKey="spam" className="p-0">
                                                        <span className="list-group-item-action ">
                                                            <i className="fa fa-exclamation-circle mr-2"></i> Spam
                                                        </span>
                                                    </Nav.Link>
                                                </li>
                                                <li className="list-group-item">
                                                    <Nav.Link eventKey="trash" className="p-0">
                                                        <span className="list-group-item-action ">
                                                            <i className="fa fa-trash mr-2"></i> Trash
                                                        </span>
                                                    </Nav.Link>
                                                </li>
                                                <li className="list-group-item">
                                                    <hr />
                                                </li>
                                                <li className="list-group-item">
                                                    <Nav.Link eventKey="starred" className="p-0">
                                                        <span className="list-group-item-action ">
                                                            <i className="fa fa-star mr-2"></i> Starred
                                                        </span>
                                                    </Nav.Link>
                                                </li>
                                                <li className="list-group-item" disabled="">
                                                    <Nav.Link eventKey="important" className="p-0">
                                                        <span className="list-group-item-action">
                                                            <i className="fa fa-bookmark mr-2"></i> Important
                                                        </span>
                                                    </Nav.Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>


                                    <div className="right-part contact-list bg-white">
                                        <Tab.Content>
                                            <Tab.Pane eventKey="inbox">
                                                <Tab.Container defaultActiveKey="inbox-msg-1">
                                                    <div className="right-left-part show-right-left-panel"><span className="bg-primary show-left-part text-white d-block d-lg-none "><i className="fa fa-chevron-right"></i></span>
                                                        <div className="bg-light p-3">
                                                            <input type="text" className="form-control" placeholder="Search emails" value="" />
                                                        </div>
                                                        <div className="bg-light px-3 pb-3 border-bottom">
                                                            <div className="d-flex justify-content-between" role="group">
                                                                <button type="button" className="btn btn-outline-secondary"><i className="fa fa-trash"></i></button>
                                                                <div className="">
                                                                    <div className="btn-group " role="group">
                                                                        <div className="dropdown">
                                                                            <button type="button" aria-haspopup="true" aria-expanded="false" className="btn btn btn-secondary"><i className="fa fa-folder"></i></button>
                                                                            <div tabindex="-1" role="menu" aria-hidden="true" className="dropdown-menu">
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Inbox</button>
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Sent</button>
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Draft</button>
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Spam</button>
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Trash</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="">
                                                                    <div className="btn-group" role="group">
                                                                        <div className="dropdown">
                                                                            <button type="button" aria-haspopup="true" aria-expanded="false" className="btn  btn btn-secondary"><i className="fa fa-tags"></i></button>
                                                                            <div tabindex="-1" role="menu" aria-hidden="true" className="dropdown-menu">
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Promotional</button>
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Social</button>
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Health</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <ul className="list-group email-list">
                                                            <li className="list-group-item p-3 border-bottom ">
                                                                <Nav.Link eventKey="inbox-msg-1" className="p-0">
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <h6 className="font-bold mb-1 mt-1">Robert Smith</h6>
                                                                            <div>
                                                                                <p className="text-truncate w-100 mb-0">Richard McClintock, a Latin professor at Hampden-Sydney</p>
                                                                                <div className="mt-2 d-flex align-items-center">
                                                                                    <div className="mr-2"><span className="badge badge-warning">Promotional</span></div>
                                                                                    <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(73, 80, 87)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                    <div className="ml-auto"><span className="text-muted font-12">7:10 AM</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                            <li className="list-group-item p-3 border-bottom bg-light">
                                                                <Nav.Link eventKey="inbox-msg-2" className="p-0">
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <h6 className="font-bold mb-1 mt-1">David Smith</h6>
                                                                            <div>
                                                                                <p className="text-truncate w-100 mb-0">The standard chunk of Lorem Ipsum used since the 1500s</p>
                                                                                <div className="mt-2 d-flex align-items-center">
                                                                                    <div className="mr-2"><span className="badge badge-success">Social</span></div>
                                                                                    <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(73, 80, 87)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                    <div className="ml-auto"><span className="text-muted font-12">5:45 PM</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                            <li className="list-group-item p-3 border-bottom ">
                                                                <Nav.Link eventKey="inbox-msg-3" className="p-0">
                                                                    <div className="row"> <div className="col-10">
                                                                        <h6 className="font-bold mb-1 mt-1">Maria Rodriguez</h6>
                                                                        <div>
                                                                            <p className="text-truncate w-100 mb-0">Cicero are also reproduced in their exact original form</p>
                                                                            <div className="mt-2 d-flex align-items-center">
                                                                                <div className="mr-2"><span className="badge badge-danger">Health</span></div>
                                                                                <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(73, 80, 87)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                <div className="ml-auto"><span className="text-muted font-12">11:32 AM</span></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                            <li className="list-group-item p-3 border-bottom ">
                                                                <Nav.Link eventKey="inbox-msg-4" className="p-0">
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <h6 className="font-bold mb-1 mt-1">Maria Hernandez</h6>
                                                                            <div>
                                                                                <p className="text-truncate w-100 mb-0">the majority have suffered alteration in some form, by injected </p>
                                                                                <div className="mt-2 d-flex align-items-center">
                                                                                    <div className="mr-2"><span className="badge "></span></div>
                                                                                    <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(73, 80, 87)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                    <div className="ml-auto"><span className="text-muted font-12">11:55 AM</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                            <li className="list-group-item p-3 border-bottom ">
                                                                <Nav.Link eventKey="inbox-msg-5" className="p-0">
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <h6 className="font-bold mb-1 mt-1">Robert Smith</h6>
                                                                            <div>
                                                                                <p className="text-truncate w-100 mb-0">Lorem Ipsum is therefore always free from repetition, injected humour</p>
                                                                                <div className="mt-2 d-flex align-items-center">
                                                                                    <div className="mr-2"><span className="badge badge-danger">Health</span></div>
                                                                                    <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(255, 193, 7)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                    <div className="ml-auto"><span className="text-muted font-12">6:58 PM</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                            <li className="list-group-item p-3 border-bottom ">
                                                                <Nav.Link eventKey="inbox-msg-6" className="p-0">
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <h6 className="font-bold mb-1 mt-1">Maria Hernandez</h6>
                                                                            <div>
                                                                                <p className="text-truncate w-100 mb-0">scrambled it to make a type specimen book</p>
                                                                                <div className="mt-2 d-flex align-items-center">
                                                                                    <div className="mr-2"><span className="badge badge-warning">Promotional</span></div>
                                                                                    <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(73, 80, 87)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                    <div className="ml-auto"><span className="text-muted font-12">7:03 AM</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                            <li className="list-group-item p-3 border-bottom ">
                                                                <Nav.Link eventKey="inbox-msg-7" className="p-0">
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <h6 className="font-bold mb-1 mt-1">James Johnson</h6>
                                                                            <div>
                                                                                <p className="text-truncate w-100 mb-0">the 1960s with the release of Letraset sheets containings</p>
                                                                                <div className="mt-2 d-flex align-items-center">
                                                                                    <div className="mr-2"><span className="badge badge-success">Social</span></div>
                                                                                    <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(255, 193, 7)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                    <div className="ml-auto"><span className="text-muted font-12">9:11 PM</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                        </ul>

                                                    </div>

                                                    <div className="right-right-part show-right-right-panel"><span className="hide-right-right-part d-block d-md-none right-right-part-open"><i className="fa fa-times"></i></span>
                                                        <Tab.Content>
                                                            <Tab.Pane eventKey="inbox-msg-1">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>

                                                            <Tab.Pane eventKey="inbox-msg-2">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="inbox-msg-3">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="inbox-msg-4">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="inbox-msg-5">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="inbox-msg-6">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="inbox-msg-7">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>

                                                        </Tab.Content>
                                                    </div>
                                                </Tab.Container>
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="sent">
                                                <Tab.Container defaultActiveKey="sent-msg-1">
                                                    <div className="right-left-part show-right-left-panel"><span className="bg-primary show-left-part text-white d-block d-lg-none "><i className="fa fa-chevron-right"></i></span>
                                                        <div className="bg-light p-3">
                                                            <input type="text" className="form-control" placeholder="Search emails" value="" />
                                                        </div>
                                                        <div className="bg-light px-3 pb-3 border-bottom">
                                                            <div className="d-flex" role="group">
                                                                <button type="button" className="btn btn-outline-secondary w-100"><i className="fa fa-trash"></i></button>
                                                                <div className="w-100">
                                                                    <div className="btn-group w-100" role="group">
                                                                        <div className="w-100 dropdown">
                                                                            <button type="button" aria-haspopup="true" aria-expanded="false" className="btn  w-100 btn btn-secondary"><i className="fa fa-folder"></i></button>
                                                                            <div tabindex="-1" role="menu" aria-hidden="true" className="dropdown-menu">
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Inbox</button>
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Sent</button>
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Draft</button>
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Spam</button>
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Trash</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="w-100">
                                                                    <div className="btn-group w-100" role="group">
                                                                        <div className="w-100 dropdown">
                                                                            <button type="button" aria-haspopup="true" aria-expanded="false" className="btn  w-100 btn btn-secondary"><i className="fa fa-tags"></i></button>
                                                                            <div tabindex="-1" role="menu" aria-hidden="true" className="dropdown-menu">
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Promotional</button>
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Social</button>
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Health</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <ul className="list-group email-list">
                                                            <li className="list-group-item p-3 border-bottom ">
                                                                <Nav.Link eventKey="sent-msg-1" className="p-0">
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <h6 className="font-bold mb-1 mt-1">Robert Smith</h6>
                                                                            <div>
                                                                                <p className="text-truncate w-100 mb-0">Richard McClintock, a Latin professor at Hampden-Sydney</p>
                                                                                <div className="mt-2 d-flex align-items-center">
                                                                                    <div className="mr-2"><span className="badge badge-warning">Promotional</span></div>
                                                                                    <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(73, 80, 87)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                    <div className="ml-auto"><span className="text-muted font-12">7:10 AM</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                            <li className="list-group-item p-3 border-bottom bg-light">
                                                                <Nav.Link eventKey="sent-msg-2" className="p-0">
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <h6 className="font-bold mb-1 mt-1">David Smith</h6>
                                                                            <div>
                                                                                <p className="text-truncate w-100 mb-0">The standard chunk of Lorem Ipsum used since the 1500s</p>
                                                                                <div className="mt-2 d-flex align-items-center">
                                                                                    <div className="mr-2"><span className="badge badge-success">Social</span></div>
                                                                                    <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(73, 80, 87)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                    <div className="ml-auto"><span className="text-muted font-12">5:45 PM</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                            <li className="list-group-item p-3 border-bottom ">
                                                                <Nav.Link eventKey="sent-msg-3" className="p-0">
                                                                    <div className="row"> <div className="col-10">
                                                                        <h6 className="font-bold mb-1 mt-1">Maria Rodriguez</h6>
                                                                        <div>
                                                                            <p className="text-truncate w-100 mb-0">Cicero are also reproduced in their exact original form</p>
                                                                            <div className="mt-2 d-flex align-items-center">
                                                                                <div className="mr-2"><span className="badge badge-danger">Health</span></div>
                                                                                <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(73, 80, 87)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                <div className="ml-auto"><span className="text-muted font-12">11:32 AM</span></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                            <li className="list-group-item p-3 border-bottom ">
                                                                <Nav.Link eventKey="sent-msg-4" className="p-0">
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <h6 className="font-bold mb-1 mt-1">Maria Hernandez</h6>
                                                                            <div>
                                                                                <p className="text-truncate w-100 mb-0">the majority have suffered alteration in some form, by injected </p>
                                                                                <div className="mt-2 d-flex align-items-center">
                                                                                    <div className="mr-2"><span className="badge "></span></div>
                                                                                    <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(73, 80, 87)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                    <div className="ml-auto"><span className="text-muted font-12">11:55 AM</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                            <li className="list-group-item p-3 border-bottom ">
                                                                <Nav.Link eventKey="sent-msg-5" className="p-0">
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <h6 className="font-bold mb-1 mt-1">Robert Smith</h6>
                                                                            <div>
                                                                                <p className="text-truncate w-100 mb-0">Lorem Ipsum is therefore always free from repetition, injected humour</p>
                                                                                <div className="mt-2 d-flex align-items-center">
                                                                                    <div className="mr-2"><span className="badge badge-danger">Health</span></div>
                                                                                    <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(255, 193, 7)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                    <div className="ml-auto"><span className="text-muted font-12">6:58 PM</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                            <li className="list-group-item p-3 border-bottom ">
                                                                <Nav.Link eventKey="sent-msg-6" className="p-0">
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <h6 className="font-bold mb-1 mt-1">Maria Hernandez</h6>
                                                                            <div>
                                                                                <p className="text-truncate w-100 mb-0">scrambled it to make a type specimen book</p>
                                                                                <div className="mt-2 d-flex align-items-center">
                                                                                    <div className="mr-2"><span className="badge badge-warning">Promotional</span></div>
                                                                                    <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(73, 80, 87)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                    <div className="ml-auto"><span className="text-muted font-12">7:03 AM</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                            <li className="list-group-item p-3 border-bottom ">
                                                                <Nav.Link eventKey="sent-msg-7" className="p-0">
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <h6 className="font-bold mb-1 mt-1">James Johnson</h6>
                                                                            <div>
                                                                                <p className="text-truncate w-100 mb-0">the 1960s with the release of Letraset sheets containings</p>
                                                                                <div className="mt-2 d-flex align-items-center">
                                                                                    <div className="mr-2"><span className="badge badge-success">Social</span></div>
                                                                                    <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(255, 193, 7)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                    <div className="ml-auto"><span className="text-muted font-12">9:11 PM</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                        </ul>

                                                    </div>

                                                    <div className="right-right-part show-right-right-panel"><span className="hide-right-right-part d-block d-md-none right-right-part-open"><i className="fa fa-times"></i></span>
                                                        <Tab.Content>
                                                            <Tab.Pane eventKey="sent-msg-1">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>

                                                            <Tab.Pane eventKey="sent-msg-2">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="sent-msg-3">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="sent-msg-4">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="sent-msg-5">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="sent-msg-6">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="sent-msg-7">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>

                                                        </Tab.Content>
                                                    </div>
                                                </Tab.Container>
                                            </Tab.Pane>


                                            <Tab.Pane eventKey="drafts">
                                                <Tab.Container defaultActiveKey="drafts-msg-1">
                                                    <div className="right-left-part show-right-left-panel"><span className="bg-primary show-left-part text-white d-block d-lg-none "><i className="fa fa-chevron-right"></i></span>
                                                        <div className="bg-light p-3">
                                                            <input type="text" className="form-control" placeholder="Search emails" value="" />
                                                        </div>
                                                        <div className="bg-light px-3 pb-3 border-bottom">
                                                            <div className="d-flex" role="group">
                                                                <button type="button" className="btn btn-outline-secondary w-100"><i className="fa fa-trash"></i></button>
                                                                <div className="w-100">
                                                                    <div className="btn-group w-100" role="group">
                                                                        <div className="w-100 dropdown">
                                                                            <button type="button" aria-haspopup="true" aria-expanded="false" className="btn  w-100 btn btn-secondary"><i className="fa fa-folder"></i></button>
                                                                            <div tabindex="-1" role="menu" aria-hidden="true" className="dropdown-menu">
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Inbox</button>
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Sent</button>
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Draft</button>
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Spam</button>
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Trash</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="w-100">
                                                                    <div className="btn-group w-100" role="group">
                                                                        <div className="w-100 dropdown">
                                                                            <button type="button" aria-haspopup="true" aria-expanded="false" className="btn  w-100 btn btn-secondary"><i className="fa fa-tags"></i></button>
                                                                            <div tabindex="-1" role="menu" aria-hidden="true" className="dropdown-menu">
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Promotional</button>
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Social</button>
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Health</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <ul className="list-group email-list">
                                                            <li className="list-group-item p-3 border-bottom ">
                                                                <Nav.Link eventKey="drafts-msg-1" className="p-0">
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <h6 className="font-bold mb-1 mt-1">Robert Smith</h6>
                                                                            <div>
                                                                                <p className="text-truncate w-100 mb-0">Richard McClintock, a Latin professor at Hampden-Sydney</p>
                                                                                <div className="mt-2 d-flex align-items-center">
                                                                                    <div className="mr-2"><span className="badge badge-warning">Promotional</span></div>
                                                                                    <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(73, 80, 87)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                    <div className="ml-auto"><span className="text-muted font-12">7:10 AM</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                            <li className="list-group-item p-3 border-bottom bg-light">
                                                                <Nav.Link eventKey="drafts-msg-2" className="p-0">
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <h6 className="font-bold mb-1 mt-1">David Smith</h6>
                                                                            <div>
                                                                                <p className="text-truncate w-100 mb-0">The standard chunk of Lorem Ipsum used since the 1500s</p>
                                                                                <div className="mt-2 d-flex align-items-center">
                                                                                    <div className="mr-2"><span className="badge badge-success">Social</span></div>
                                                                                    <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(73, 80, 87)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                    <div className="ml-auto"><span className="text-muted font-12">5:45 PM</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                            <li className="list-group-item p-3 border-bottom ">
                                                                <Nav.Link eventKey="drafts-msg-3" className="p-0">
                                                                    <div className="row"> <div className="col-10">
                                                                        <h6 className="font-bold mb-1 mt-1">Maria Rodriguez</h6>
                                                                        <div>
                                                                            <p className="text-truncate w-100 mb-0">Cicero are also reproduced in their exact original form</p>
                                                                            <div className="mt-2 d-flex align-items-center">
                                                                                <div className="mr-2"><span className="badge badge-danger">Health</span></div>
                                                                                <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(73, 80, 87)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                <div className="ml-auto"><span className="text-muted font-12">11:32 AM</span></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                            <li className="list-group-item p-3 border-bottom ">
                                                                <Nav.Link eventKey="drafts-msg-4" className="p-0">
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <h6 className="font-bold mb-1 mt-1">Maria Hernandez</h6>
                                                                            <div>
                                                                                <p className="text-truncate w-100 mb-0">the majority have suffered alteration in some form, by injected </p>
                                                                                <div className="mt-2 d-flex align-items-center">
                                                                                    <div className="mr-2"><span className="badge "></span></div>
                                                                                    <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(73, 80, 87)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                    <div className="ml-auto"><span className="text-muted font-12">11:55 AM</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                            <li className="list-group-item p-3 border-bottom ">
                                                                <Nav.Link eventKey="drafts-msg-5" className="p-0">
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <h6 className="font-bold mb-1 mt-1">Robert Smith</h6>
                                                                            <div>
                                                                                <p className="text-truncate w-100 mb-0">Lorem Ipsum is therefore always free from repetition, injected humour</p>
                                                                                <div className="mt-2 d-flex align-items-center">
                                                                                    <div className="mr-2"><span className="badge badge-danger">Health</span></div>
                                                                                    <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(255, 193, 7)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                    <div className="ml-auto"><span className="text-muted font-12">6:58 PM</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                            <li className="list-group-item p-3 border-bottom ">
                                                                <Nav.Link eventKey="drafts-msg-6" className="p-0">
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <h6 className="font-bold mb-1 mt-1">Maria Hernandez</h6>
                                                                            <div>
                                                                                <p className="text-truncate w-100 mb-0">scrambled it to make a type specimen book</p>
                                                                                <div className="mt-2 d-flex align-items-center">
                                                                                    <div className="mr-2"><span className="badge badge-warning">Promotional</span></div>
                                                                                    <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(73, 80, 87)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                    <div className="ml-auto"><span className="text-muted font-12">7:03 AM</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                            <li className="list-group-item p-3 border-bottom ">
                                                                <Nav.Link eventKey="drafts-msg-7" className="p-0">
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <h6 className="font-bold mb-1 mt-1">James Johnson</h6>
                                                                            <div>
                                                                                <p className="text-truncate w-100 mb-0">the 1960s with the release of Letraset sheets containings</p>
                                                                                <div className="mt-2 d-flex align-items-center">
                                                                                    <div className="mr-2"><span className="badge badge-success">Social</span></div>
                                                                                    <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(255, 193, 7)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                    <div className="ml-auto"><span className="text-muted font-12">9:11 PM</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                        </ul>

                                                    </div>

                                                    <div className="right-right-part show-right-right-panel"><span className="hide-right-right-part d-block d-md-none right-right-part-open"><i className="fa fa-times"></i></span>
                                                        <Tab.Content>
                                                            <Tab.Pane eventKey="drafts-msg-1">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>

                                                            <Tab.Pane eventKey="drafts-msg-2">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="drafts-msg-3">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="drafts-msg-4">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="drafts-msg-5">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="drafts-msg-6">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="drafts-msg-7">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>

                                                        </Tab.Content>
                                                    </div>
                                                </Tab.Container>
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="spam">
                                                <Tab.Container defaultActiveKey="spam-msg-1">
                                                    <div className="right-left-part show-right-left-panel"><span className="bg-primary show-left-part text-white d-block d-lg-none "><i className="fa fa-chevron-right"></i></span>
                                                        <div className="bg-light p-3">
                                                            <input type="text" className="form-control" placeholder="Search emails" value="" />
                                                        </div>
                                                        <div className="bg-light px-3 pb-3 border-bottom">
                                                            <div className="d-flex" role="group">
                                                                <button type="button" className="btn btn-outline-secondary w-100"><i className="fa fa-trash"></i></button>
                                                                <div className="w-100">
                                                                    <div className="btn-group w-100" role="group">
                                                                        <div className="w-100 dropdown">
                                                                            <button type="button" aria-haspopup="true" aria-expanded="false" className="btn  w-100 btn btn-secondary"><i className="fa fa-folder"></i></button>
                                                                            <div tabindex="-1" role="menu" aria-hidden="true" className="dropdown-menu">
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Inbox</button>
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Sent</button>
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Draft</button>
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Spam</button>
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Trash</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="w-100">
                                                                    <div className="btn-group w-100" role="group">
                                                                        <div className="w-100 dropdown">
                                                                            <button type="button" aria-haspopup="true" aria-expanded="false" className="btn  w-100 btn btn-secondary"><i className="fa fa-tags"></i></button>
                                                                            <div tabindex="-1" role="menu" aria-hidden="true" className="dropdown-menu">
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Promotional</button>
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Social</button>
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Health</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <ul className="list-group email-list">
                                                            <li className="list-group-item p-3 border-bottom ">
                                                                <Nav.Link eventKey="spam-msg-1" className="p-0">
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <h6 className="font-bold mb-1 mt-1">Robert Smith</h6>
                                                                            <div>
                                                                                <p className="text-truncate w-100 mb-0">Richard McClintock, a Latin professor at Hampden-Sydney</p>
                                                                                <div className="mt-2 d-flex align-items-center">
                                                                                    <div className="mr-2"><span className="badge badge-warning">Promotional</span></div>
                                                                                    <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(73, 80, 87)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                    <div className="ml-auto"><span className="text-muted font-12">7:10 AM</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                            <li className="list-group-item p-3 border-bottom bg-light">
                                                                <Nav.Link eventKey="spam-msg-2" className="p-0">
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <h6 className="font-bold mb-1 mt-1">David Smith</h6>
                                                                            <div>
                                                                                <p className="text-truncate w-100 mb-0">The standard chunk of Lorem Ipsum used since the 1500s</p>
                                                                                <div className="mt-2 d-flex align-items-center">
                                                                                    <div className="mr-2"><span className="badge badge-success">Social</span></div>
                                                                                    <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(73, 80, 87)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                    <div className="ml-auto"><span className="text-muted font-12">5:45 PM</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                            <li className="list-group-item p-3 border-bottom ">
                                                                <Nav.Link eventKey="spam-msg-3" className="p-0">
                                                                    <div className="row"> <div className="col-10">
                                                                        <h6 className="font-bold mb-1 mt-1">Maria Rodriguez</h6>
                                                                        <div>
                                                                            <p className="text-truncate w-100 mb-0">Cicero are also reproduced in their exact original form</p>
                                                                            <div className="mt-2 d-flex align-items-center">
                                                                                <div className="mr-2"><span className="badge badge-danger">Health</span></div>
                                                                                <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(73, 80, 87)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                <div className="ml-auto"><span className="text-muted font-12">11:32 AM</span></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                            <li className="list-group-item p-3 border-bottom ">
                                                                <Nav.Link eventKey="spam-msg-4" className="p-0">
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <h6 className="font-bold mb-1 mt-1">Maria Hernandez</h6>
                                                                            <div>
                                                                                <p className="text-truncate w-100 mb-0">the majority have suffered alteration in some form, by injected </p>
                                                                                <div className="mt-2 d-flex align-items-center">
                                                                                    <div className="mr-2"><span className="badge "></span></div>
                                                                                    <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(73, 80, 87)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                    <div className="ml-auto"><span className="text-muted font-12">11:55 AM</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                            <li className="list-group-item p-3 border-bottom ">
                                                                <Nav.Link eventKey="spam-msg-5" className="p-0">
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <h6 className="font-bold mb-1 mt-1">Robert Smith</h6>
                                                                            <div>
                                                                                <p className="text-truncate w-100 mb-0">Lorem Ipsum is therefore always free from repetition, injected humour</p>
                                                                                <div className="mt-2 d-flex align-items-center">
                                                                                    <div className="mr-2"><span className="badge badge-danger">Health</span></div>
                                                                                    <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(255, 193, 7)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                    <div className="ml-auto"><span className="text-muted font-12">6:58 PM</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                            <li className="list-group-item p-3 border-bottom ">
                                                                <Nav.Link eventKey="spam-msg-6" className="p-0">
                                                                    <div className="row">                                                                            <div className="col-1">
                                                                        <div className="custom-control custom-checkbox">
                                                                            <input type="checkbox" id="check18" className="custom-control-input" />
                                                                            <label className="custom-control-label" for="check18"> </label>
                                                                        </div>
                                                                    </div>
                                                                        <div className="col-10">
                                                                            <h6 className="font-bold mb-1 mt-1">Maria Hernandez</h6>
                                                                            <div>
                                                                                <p className="text-truncate w-100 mb-0">scrambled it to make a type specimen book</p>
                                                                                <div className="mt-2 d-flex align-items-center">
                                                                                    <div className="mr-2"><span className="badge badge-warning">Promotional</span></div>
                                                                                    <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(73, 80, 87)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                    <div className="ml-auto"><span className="text-muted font-12">7:03 AM</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                            <li className="list-group-item p-3 border-bottom ">
                                                                <Nav.Link eventKey="spam-msg-7" className="p-0">
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <h6 className="font-bold mb-1 mt-1">James Johnson</h6>
                                                                            <div>
                                                                                <p className="text-truncate w-100 mb-0">the 1960s with the release of Letraset sheets containings</p>
                                                                                <div className="mt-2 d-flex align-items-center">
                                                                                    <div className="mr-2"><span className="badge badge-success">Social</span></div>
                                                                                    <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(255, 193, 7)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                    <div className="ml-auto"><span className="text-muted font-12">9:11 PM</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                        </ul>

                                                    </div>

                                                    <div className="right-right-part show-right-right-panel"><span className="hide-right-right-part d-block d-md-none right-right-part-open"><i className="fa fa-times"></i></span>
                                                        <Tab.Content>
                                                            <Tab.Pane eventKey="spam-msg-1">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>

                                                            <Tab.Pane eventKey="spam-msg-2">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="spam-msg-3">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="spam-msg-4">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="spam-msg-5">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="spam-msg-6">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="spam-msg-7">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>

                                                        </Tab.Content>
                                                    </div>
                                                </Tab.Container>
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="trash">
                                                <Tab.Container defaultActiveKey="trash-msg-1">
                                                    <div className="right-left-part show-right-left-panel"><span className="bg-primary show-left-part text-white d-block d-lg-none "><i className="fa fa-chevron-right"></i></span>
                                                        <div className="bg-light p-3">
                                                            <input type="text" className="form-control" placeholder="Search emails" value="" />
                                                        </div>
                                                        <div className="bg-light px-3 pb-3 border-bottom">
                                                            <div className="d-flex" role="group">
                                                                <button type="button" className="btn btn-outline-secondary w-100"><i className="fa fa-trash"></i></button>
                                                                <div className="w-100">
                                                                    <div className="btn-group w-100" role="group">
                                                                        <div className="w-100 dropdown">
                                                                            <button type="button" aria-haspopup="true" aria-expanded="false" className="btn  w-100 btn btn-secondary"><i className="fa fa-folder"></i></button>
                                                                            <div tabindex="-1" role="menu" aria-hidden="true" className="dropdown-menu">
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Inbox</button>
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Sent</button>
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Draft</button>
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Spam</button>
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Trash</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="w-100">
                                                                    <div className="btn-group w-100" role="group">
                                                                        <div className="w-100 dropdown">
                                                                            <button type="button" aria-haspopup="true" aria-expanded="false" className="btn  w-100 btn btn-secondary"><i className="fa fa-tags"></i></button>
                                                                            <div tabindex="-1" role="menu" aria-hidden="true" className="dropdown-menu">
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Promotional</button>
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Social</button>
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Health</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <ul className="list-group email-list">
                                                            <li className="list-group-item p-3 border-bottom ">
                                                                <Nav.Link eventKey="trash-msg-1" className="p-0">
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <h6 className="font-bold mb-1 mt-1">Robert Smith</h6>
                                                                            <div>
                                                                                <p className="text-truncate w-100 mb-0">Richard McClintock, a Latin professor at Hampden-Sydney</p>
                                                                                <div className="mt-2 d-flex align-items-center">
                                                                                    <div className="mr-2"><span className="badge badge-warning">Promotional</span></div>
                                                                                    <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(73, 80, 87)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                    <div className="ml-auto"><span className="text-muted font-12">7:10 AM</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                            <li className="list-group-item p-3 border-bottom bg-light">
                                                                <Nav.Link eventKey="trash-msg-2" className="p-0">
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <h6 className="font-bold mb-1 mt-1">David Smith</h6>
                                                                            <div>
                                                                                <p className="text-truncate w-100 mb-0">The standard chunk of Lorem Ipsum used since the 1500s</p>
                                                                                <div className="mt-2 d-flex align-items-center">
                                                                                    <div className="mr-2"><span className="badge badge-success">Social</span></div>
                                                                                    <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(73, 80, 87)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                    <div className="ml-auto"><span className="text-muted font-12">5:45 PM</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                            <li className="list-group-item p-3 border-bottom ">
                                                                <Nav.Link eventKey="trash-msg-3" className="p-0">
                                                                    <div className="row"> <div className="col-10">
                                                                        <h6 className="font-bold mb-1 mt-1">Maria Rodriguez</h6>
                                                                        <div>
                                                                            <p className="text-truncate w-100 mb-0">Cicero are also reproduced in their exact original form</p>
                                                                            <div className="mt-2 d-flex align-items-center">
                                                                                <div className="mr-2"><span className="badge badge-danger">Health</span></div>
                                                                                <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(73, 80, 87)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                <div className="ml-auto"><span className="text-muted font-12">11:32 AM</span></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                            <li className="list-group-item p-3 border-bottom ">
                                                                <Nav.Link eventKey="trash-msg-4" className="p-0">
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <h6 className="font-bold mb-1 mt-1">Maria Hernandez</h6>
                                                                            <div>
                                                                                <p className="text-truncate w-100 mb-0">the majority have suffered alteration in some form, by injected </p>
                                                                                <div className="mt-2 d-flex align-items-center">
                                                                                    <div className="mr-2"><span className="badge "></span></div>
                                                                                    <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(73, 80, 87)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                    <div className="ml-auto"><span className="text-muted font-12">11:55 AM</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                            <li className="list-group-item p-3 border-bottom ">
                                                                <Nav.Link eventKey="trash-msg-5" className="p-0">
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <h6 className="font-bold mb-1 mt-1">Robert Smith</h6>
                                                                            <div>
                                                                                <p className="text-truncate w-100 mb-0">Lorem Ipsum is therefore always free from repetition, injected humour</p>
                                                                                <div className="mt-2 d-flex align-items-center">
                                                                                    <div className="mr-2"><span className="badge badge-danger">Health</span></div>
                                                                                    <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(255, 193, 7)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                    <div className="ml-auto"><span className="text-muted font-12">6:58 PM</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                            <li className="list-group-item p-3 border-bottom ">
                                                                <Nav.Link eventKey="trash-msg-6" className="p-0">
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <h6 className="font-bold mb-1 mt-1">Maria Hernandez</h6>
                                                                            <div>
                                                                                <p className="text-truncate w-100 mb-0">scrambled it to make a type specimen book</p>
                                                                                <div className="mt-2 d-flex align-items-center">
                                                                                    <div className="mr-2"><span className="badge badge-warning">Promotional</span></div>
                                                                                    <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(73, 80, 87)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                    <div className="ml-auto"><span className="text-muted font-12">7:03 AM</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                            <li className="list-group-item p-3 border-bottom ">
                                                                <Nav.Link eventKey="trash-msg-7" className="p-0">
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <h6 className="font-bold mb-1 mt-1">James Johnson</h6>
                                                                            <div>
                                                                                <p className="text-truncate w-100 mb-0">the 1960s with the release of Letraset sheets containings</p>
                                                                                <div className="mt-2 d-flex align-items-center">
                                                                                    <div className="mr-2"><span className="badge badge-success">Social</span></div>
                                                                                    <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(255, 193, 7)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                    <div className="ml-auto"><span className="text-muted font-12">9:11 PM</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                        </ul>

                                                    </div>

                                                    <div className="right-right-part show-right-right-panel"><span className="hide-right-right-part d-block d-md-none right-right-part-open"><i className="fa fa-times"></i></span>
                                                        <Tab.Content>
                                                            <Tab.Pane eventKey="trash-msg-1">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>

                                                            <Tab.Pane eventKey="trash-msg-2">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="trash-msg-3">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="trash-msg-4">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="trash-msg-5">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="trash-msg-6">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="trash-msg-7">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>

                                                        </Tab.Content>
                                                    </div>
                                                </Tab.Container>
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="starred">
                                                <Tab.Container defaultActiveKey="starred-msg-1">
                                                    <div className="right-left-part show-right-left-panel"><span className="bg-primary show-left-part text-white d-block d-lg-none "><i className="fa fa-chevron-right"></i></span>
                                                        <div className="bg-light p-3">
                                                            <input type="text" className="form-control" placeholder="Search emails" value="" />
                                                        </div>
                                                        <div className="bg-light px-3 pb-3 border-bottom">
                                                            <div className="d-flex" role="group">
                                                                <button type="button" className="btn btn-outline-secondary w-100"><i className="fa fa-trash"></i></button>
                                                                <div className="w-100">
                                                                    <div className="btn-group w-100" role="group">
                                                                        <div className="w-100 dropdown">
                                                                            <button type="button" aria-haspopup="true" aria-expanded="false" className="btn  w-100 btn btn-secondary"><i className="fa fa-folder"></i></button>
                                                                            <div tabindex="-1" role="menu" aria-hidden="true" className="dropdown-menu">
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Inbox</button>
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Sent</button>
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Draft</button>
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Spam</button>
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Trash</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="w-100">
                                                                    <div className="btn-group w-100" role="group">
                                                                        <div className="w-100 dropdown">
                                                                            <button type="button" aria-haspopup="true" aria-expanded="false" className="btn  w-100 btn btn-secondary"><i className="fa fa-tags"></i></button>
                                                                            <div tabindex="-1" role="menu" aria-hidden="true" className="dropdown-menu">
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Promotional</button>
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Social</button>
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Health</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <ul className="list-group email-list">
                                                            <li className="list-group-item p-3 border-bottom ">
                                                                <Nav.Link eventKey="starred-msg-1" className="p-0">
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <h6 className="font-bold mb-1 mt-1">Robert Smith</h6>
                                                                            <div>
                                                                                <p className="text-truncate w-100 mb-0">Richard McClintock, a Latin professor at Hampden-Sydney</p>
                                                                                <div className="mt-2 d-flex align-items-center">
                                                                                    <div className="mr-2"><span className="badge badge-warning">Promotional</span></div>
                                                                                    <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(73, 80, 87)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                    <div className="ml-auto"><span className="text-muted font-12">7:10 AM</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                            <li className="list-group-item p-3 border-bottom bg-light">
                                                                <Nav.Link eventKey="starred-msg-2" className="p-0">
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <h6 className="font-bold mb-1 mt-1">David Smith</h6>
                                                                            <div>
                                                                                <p className="text-truncate w-100 mb-0">The standard chunk of Lorem Ipsum used since the 1500s</p>
                                                                                <div className="mt-2 d-flex align-items-center">
                                                                                    <div className="mr-2"><span className="badge badge-success">Social</span></div>
                                                                                    <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(73, 80, 87)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                    <div className="ml-auto"><span className="text-muted font-12">5:45 PM</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                            <li className="list-group-item p-3 border-bottom ">
                                                                <Nav.Link eventKey="starred-msg-3" className="p-0">
                                                                    <div className="row"> <div className="col-10">
                                                                        <h6 className="font-bold mb-1 mt-1">Maria Rodriguez</h6>
                                                                        <div>
                                                                            <p className="text-truncate w-100 mb-0">Cicero are also reproduced in their exact original form</p>
                                                                            <div className="mt-2 d-flex align-items-center">
                                                                                <div className="mr-2"><span className="badge badge-danger">Health</span></div>
                                                                                <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(73, 80, 87)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                <div className="ml-auto"><span className="text-muted font-12">11:32 AM</span></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                            <li className="list-group-item p-3 border-bottom ">
                                                                <Nav.Link eventKey="starred-msg-4" className="p-0">
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <h6 className="font-bold mb-1 mt-1">Maria Hernandez</h6>
                                                                            <div>
                                                                                <p className="text-truncate w-100 mb-0">the majority have suffered alteration in some form, by injected </p>
                                                                                <div className="mt-2 d-flex align-items-center">
                                                                                    <div className="mr-2"><span className="badge "></span></div>
                                                                                    <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(73, 80, 87)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                    <div className="ml-auto"><span className="text-muted font-12">11:55 AM</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                            <li className="list-group-item p-3 border-bottom ">
                                                                <Nav.Link eventKey="starred-msg-5" className="p-0">
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <h6 className="font-bold mb-1 mt-1">Robert Smith</h6>
                                                                            <div>
                                                                                <p className="text-truncate w-100 mb-0">Lorem Ipsum is therefore always free from repetition, injected humour</p>
                                                                                <div className="mt-2 d-flex align-items-center">
                                                                                    <div className="mr-2"><span className="badge badge-danger">Health</span></div>
                                                                                    <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(255, 193, 7)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                    <div className="ml-auto"><span className="text-muted font-12">6:58 PM</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                            <li className="list-group-item p-3 border-bottom ">
                                                                <Nav.Link eventKey="starred-msg-6" className="p-0">
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <h6 className="font-bold mb-1 mt-1">Maria Hernandez</h6>
                                                                            <div>
                                                                                <p className="text-truncate w-100 mb-0">scrambled it to make a type specimen book</p>
                                                                                <div className="mt-2 d-flex align-items-center">
                                                                                    <div className="mr-2"><span className="badge badge-warning">Promotional</span></div>
                                                                                    <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(73, 80, 87)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                    <div className="ml-auto"><span className="text-muted font-12">7:03 AM</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                            <li className="list-group-item p-3 border-bottom ">
                                                                <Nav.Link eventKey="starred-msg-7" className="p-0">
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <h6 className="font-bold mb-1 mt-1">James Johnson</h6>
                                                                            <div>
                                                                                <p className="text-truncate w-100 mb-0">the 1960s with the release of Letraset sheets containings</p>
                                                                                <div className="mt-2 d-flex align-items-center">
                                                                                    <div className="mr-2"><span className="badge badge-success">Social</span></div>
                                                                                    <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(255, 193, 7)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                    <div className="ml-auto"><span className="text-muted font-12">9:11 PM</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                        </ul>

                                                    </div>

                                                    <div className="right-right-part show-right-right-panel"><span className="hide-right-right-part d-block d-md-none right-right-part-open"><i className="fa fa-times"></i></span>
                                                        <Tab.Content>
                                                            <Tab.Pane eventKey="starred-msg-1">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>

                                                            <Tab.Pane eventKey="starred-msg-2">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="starred-msg-3">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="starred-msg-4">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="starred-msg-5">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="starred-msg-6">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="starred-msg-7">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>

                                                        </Tab.Content>
                                                    </div>
                                                </Tab.Container>
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="important">
                                                <Tab.Container defaultActiveKey="important-msg-1">
                                                    <div className="right-left-part show-right-left-panel"><span className="bg-primary show-left-part text-white d-block d-lg-none "><i className="fa fa-chevron-right"></i></span>
                                                        <div className="bg-light p-3">
                                                            <input type="text" className="form-control" placeholder="Search emails" value="" />
                                                        </div>
                                                        <div className="bg-light px-3 pb-3 border-bottom">
                                                            <div className="d-flex" role="group">
                                                                <button type="button" className="btn btn-outline-secondary w-100"><i className="fa fa-trash"></i></button>
                                                                <div className="w-100">
                                                                    <div className="btn-group w-100" role="group">
                                                                        <div className="w-100 dropdown">
                                                                            <button type="button" aria-haspopup="true" aria-expanded="false" className="btn  w-100 btn btn-secondary"><i className="fa fa-folder"></i></button>
                                                                            <div tabindex="-1" role="menu" aria-hidden="true" className="dropdown-menu">
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Inbox</button>
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Sent</button>
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Draft</button>
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Spam</button>
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Trash</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="w-100">
                                                                    <div className="btn-group w-100" role="group">
                                                                        <div className="w-100 dropdown">
                                                                            <button type="button" aria-haspopup="true" aria-expanded="false" className="btn  w-100 btn btn-secondary"><i className="fa fa-tags"></i></button>
                                                                            <div tabindex="-1" role="menu" aria-hidden="true" className="dropdown-menu">
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Promotional</button>
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Social</button>
                                                                                <button type="button" tabindex="0" role="menuitem" className="dropdown-item">Health</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <ul className="list-group email-list">
                                                            <li className="list-group-item p-3 border-bottom ">
                                                                <Nav.Link eventKey="important-msg-1" className="p-0">
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <h6 className="font-bold mb-1 mt-1">Robert Smith</h6>
                                                                            <div>
                                                                                <p className="text-truncate w-100 mb-0">Richard McClintock, a Latin professor at Hampden-Sydney</p>
                                                                                <div className="mt-2 d-flex align-items-center">
                                                                                    <div className="mr-2"><span className="badge badge-warning">Promotional</span></div>
                                                                                    <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(73, 80, 87)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                    <div className="ml-auto"><span className="text-muted font-12">7:10 AM</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                            <li className="list-group-item p-3 border-bottom bg-light">
                                                                <Nav.Link eventKey="important-msg-2" className="p-0">
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <h6 className="font-bold mb-1 mt-1">David Smith</h6>
                                                                            <div>
                                                                                <p className="text-truncate w-100 mb-0">The standard chunk of Lorem Ipsum used since the 1500s</p>
                                                                                <div className="mt-2 d-flex align-items-center">
                                                                                    <div className="mr-2"><span className="badge badge-success">Social</span></div>
                                                                                    <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(73, 80, 87)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                    <div className="ml-auto"><span className="text-muted font-12">5:45 PM</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                            <li className="list-group-item p-3 border-bottom ">
                                                                <Nav.Link eventKey="important-msg-3" className="p-0">
                                                                    <div className="row"> <div className="col-10">
                                                                        <h6 className="font-bold mb-1 mt-1">Maria Rodriguez</h6>
                                                                        <div>
                                                                            <p className="text-truncate w-100 mb-0">Cicero are also reproduced in their exact original form</p>
                                                                            <div className="mt-2 d-flex align-items-center">
                                                                                <div className="mr-2"><span className="badge badge-danger">Health</span></div>
                                                                                <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(73, 80, 87)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                <div className="ml-auto"><span className="text-muted font-12">11:32 AM</span></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                            <li className="list-group-item p-3 border-bottom ">
                                                                <Nav.Link eventKey="important-msg-4" className="p-0">
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <h6 className="font-bold mb-1 mt-1">Maria Hernandez</h6>
                                                                            <div>
                                                                                <p className="text-truncate w-100 mb-0">the majority have suffered alteration in some form, by injected </p>
                                                                                <div className="mt-2 d-flex align-items-center">
                                                                                    <div className="mr-2"><span className="badge "></span></div>
                                                                                    <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(73, 80, 87)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                    <div className="ml-auto"><span className="text-muted font-12">11:55 AM</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                            <li className="list-group-item p-3 border-bottom ">
                                                                <Nav.Link eventKey="important-msg-5" className="p-0">
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <h6 className="font-bold mb-1 mt-1">Robert Smith</h6>
                                                                            <div>
                                                                                <p className="text-truncate w-100 mb-0">Lorem Ipsum is therefore always free from repetition, injected humour</p>
                                                                                <div className="mt-2 d-flex align-items-center">
                                                                                    <div className="mr-2"><span className="badge badge-danger">Health</span></div>
                                                                                    <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(255, 193, 7)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                    <div className="ml-auto"><span className="text-muted font-12">6:58 PM</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                            <li className="list-group-item p-3 border-bottom ">
                                                                <Nav.Link eventKey="important-msg-6" className="p-0">
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <h6 className="font-bold mb-1 mt-1">Maria Hernandez</h6>
                                                                            <div>
                                                                                <p className="text-truncate w-100 mb-0">scrambled it to make a type specimen book</p>
                                                                                <div className="mt-2 d-flex align-items-center">
                                                                                    <div className="mr-2"><span className="badge badge-warning">Promotional</span></div>
                                                                                    <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(73, 80, 87)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                    <div className="ml-auto"><span className="text-muted font-12">7:03 AM</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                            <li className="list-group-item p-3 border-bottom ">
                                                                <Nav.Link eventKey="important-msg-7" className="p-0">
                                                                    <div className="row">
                                                                        <div className="col-10">
                                                                            <h6 className="font-bold mb-1 mt-1">James Johnson</h6>
                                                                            <div>
                                                                                <p className="text-truncate w-100 mb-0">the 1960s with the release of Letraset sheets containings</p>
                                                                                <div className="mt-2 d-flex align-items-center">
                                                                                    <div className="mr-2"><span className="badge badge-success">Social</span></div>
                                                                                    <div className=""><span className="d-flex "><i className="font-14 mr-2 fa fa-star" style={{ "color": "rgb(255, 193, 7)" }}></i><i className="font-14 fa fa-bookmark" style={{ "color": "rgb(255, 193, 7)" }}></i></span></div>
                                                                                    <div className="ml-auto"><span className="text-muted font-12">9:11 PM</span></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Nav.Link>
                                                            </li>
                                                        </ul>

                                                    </div>

                                                    <div className="right-right-part show-right-right-panel"><span className="hide-right-right-part d-block d-md-none right-right-part-open"><i className="fa fa-times"></i></span>
                                                        <Tab.Content>
                                                            <Tab.Pane eventKey="important-msg-1">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>

                                                            <Tab.Pane eventKey="important-msg-2">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="important-msg-3">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="important-msg-4">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="important-msg-5">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="important-msg-6">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="important-msg-7">
                                                                <div className="mail-details">
                                                                    <div className="card-body border-bottom">
                                                                        <h4 className="mb-0">literature from 45 BC, making<span className="ml-1 badge badge-success">Social</span></h4></div>
                                                                    <div className="card-body border-bottom">
                                                                        <div className="d-flex align-items-center"><img src={require('./../../images/avatar/1.jpg')} alt="user" className="rounded-circle" width="45" />
                                                                            <div className="ml-2">
                                                                                <h5 className="mb-0 font-16 font-medium">David Smith</h5><span>to me</span></div>
                                                                            <div className="ml-auto"><span>5:45 PM</span></div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <p>One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others which creates a distinct visual impression.</p>
                                                                    </div>
                                                                </div>
                                                            </Tab.Pane>

                                                        </Tab.Content>
                                                    </div>
                                                </Tab.Container>
                                            </Tab.Pane>

                                        </Tab.Content>

                                    </div>
                                </div>

                            </Tab.Container>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
}

export default EmailInbox;