import React from 'react'
import { Row, Col, Card } from 'react-bootstrap'
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';

export default function ApiPackages() {
    interface PackageData {
        parasutId: string,
        name: string,
        description: string,
        numberOfCredits: number,
        expireInDays: number,
        price: number,
        vat: string,
        salePrice: number,
        sku: string
    }

    const history = useHistory();
    const [cards, setCards] = React.useState<PackageData[]>([]);


    React.useEffect(() => {
        if (localStorage.getItem('loginToken') === null) {
          history.push('./login');
          return;
        }

      }, [])

      const numberFormat = (value: number) =>
      new Intl.NumberFormat('tr-TR', {
   
        currency: 'TRY',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(value);

    return (
        <>
            <Row className='mt-5' style={{ justifyContent: 'center' }}>
                <Col xl={12}>
                    <Card>
                        <Card.Body>
                            <div>
                                <div id="generic_price_table">
                                    <section>
                                        <div className="container">
                                            <div className="row">
                                                {cards.map(card => {
                                                    return (
                                                    <div key={card.parasutId} className="col-md-4 ">
                                                    <div className="generic_content clearfix border">
                                                    {/* <div className="generic_content active clearfix border"> */}
                                                        <div className="generic_head_price clearfix">
                                                            <div className="generic_head_content clearfix">
                                                                <div className="head_bg"></div>
                                                                <div className="head">
                                                                    <span>{card.sku}</span>
                                                                </div>
                                                            </div>
                                                            <div className="generic_price_tag clearfix">
                                                                <span className="price">
                                                                    <span className="sign">₺</span>
                                                                    <span className="currency">{numberFormat(card.salePrice)}</span>
                                                               
                                                                    <span className="month">/6 Ay</span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="generic_feature_list">
                                                            <ul>
                                                                <li><span>{card.numberOfCredits}</span> Kredi</li>
                                                                <li>{card.description}</li>
                                                            </ul>
                                                        </div>

                                                        <div className="generic_price_btn clearfix">
                                                            <a className="" href="">İncele</a>
                                                        </div>
                                                    </div>
                                                </div>)
                                                })}
                                                
                                                
                                     
                                       
                                            </div>
                                        </div>
                                    </section>
                         
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}
