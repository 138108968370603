import React from 'react'
import { Row, Col, Card } from 'react-bootstrap'
import OtpInput from 'react-otp-input';
import image from '../../../images/otp/phone-verification.png'; // with import


export default function phoneVerification(): JSX.Element {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [otp, setOtp] = React.useState("");

    return (
        <>
            <Row className='mt-5' style={{ justifyContent: 'center' }}>
                <Col xl={12}>
                    <Card>
                        <Card.Body>
                            <div className="container height-100 d-flex justify-content-center align-items-center">

                                <div className="position-relative">
                                    
                                    <div className=" p-2 text-center">
                                    <img src={image} height="50%" width='50%'/>

                                        <h6>Hesabınızı doğrulamak için <br /> Lütfen tek kullanımlık şifreyi giriniz </h6>
                                        <div> <span>****9897'ye bir kod gönderildi.</span></div>
                                        <div className="d-flex flex-row justify-content-center radius">
                                        <OtpInput   inputStyle={{
                    fontSize: "24px",
                    width: "36px",
                    height: "36px",
                    margin: "4px",
                    borderTop: "0px",
                    borderLeft: "0px",
                    borderRight: "0px",
                    outline: "none",
                    borderColor: "#000a46"
                  }}
                  containerStyle={{
                    margin: "20px auto",
                    padding: "10px"
                  }}
        value={otp}
        onChange={setOtp}
        numInputs={6}
        separator={<span>-</span>}
      />
                                        </div>
                                        <div className="mt-4"> <button className="btn btn-primary px-4 validate">Doğrula</button> </div>
                                    </div>
                                    <div className="card-2">
                                        <div className="content d-flex justify-content-center align-items-center"> <span>Sms gelmedi mi ? </span> <a href="#" className="text-decoration-none ms-3"> Yeniden Gönder (1/3)</a> </div>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}
