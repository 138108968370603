import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';
import axios from "axios";
import { useLogin } from '../../../providers/loginProvider'


function Login() {
    const history = useHistory()
    const [userName, setUserName] = React.useState('mehmet@appac.ltd')
    const [password, setPassword] = React.useState('1234567')
    const [errorMessage, setErrorMessage] = React.useState('')
    const { login } = useLogin();

    const loginControl = (username, password) => {
        console.log(username, password);
        axios.post(`https://api.ircom.com.tr/api/auth/login`,
            { Email: username, Password: password })
            .then(response => response)
            .then((value) => {
                console.log('Login Token');
                console.log(value.data);

                if(value.data.Success){
                    login(value.data.Data.Token);
                    history.push('./profile')
                }
                else {
                    setErrorMessage(value.data.Message)
                }

            }).catch(err => {
                console.log(err.response);
                // setErrorMessage(err.response)
            })
    }

    return (
        <>
            <div className="row justify-content-center mt-5 align-items-center">
                <div className="col-md-6">
                    <div className="authincation-content">
                        <div className="row no-gutters">
                            <div className="col-xl-12">
                                <div className="auth-form">
                                    <h4 className="text-center mb-4">Sign in your account</h4>

                                    <div className="form-group">
                                        <label><strong>Email</strong></label>
                                        <input type="email" onChange={(e) => { setUserName(e.target.value) }} className="form-control" autoComplete="username" />
                                    </div>
                                    <div className="form-group">
                                        <label><strong>Password</strong></label>
                                        <input type="password" onChange={(e) => { setPassword(e.target.value) }} className="form-control" autoComplete="current-password" />
                                    </div>
                                    {errorMessage.length > 0 ? <div className="alert alert-danger" role="alert">

                                        {errorMessage}</div>
                                        : null}
                                    <div className="form-row d-flex justify-content-between mt-4 mb-2">
                                        <div className="form-group">
                                            <div className="form-check ml-2">
                                                <input className="form-check-input" type="checkbox" id="basic_checkbox_1" />
                                                <label className="form-check-label" htmlFor="basic_checkbox_1">Beni hatırla</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            {/* <a href="page-forgot-password.html">Parolamı Unuttum</a> */}
                                            <Link to={'./forgotpassword'}>Parolamı Unuttum</Link>

                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button type="button" onClick={() => loginControl(userName, password)} className="btn btn-primary btn-block">Giriş Yap</button>
                                    </div>

                                    <div className="new-account mt-3">
                                        <p>Hesabınız yok mu? <Link className="text-primary" to={'./register'}>Üye Ol</Link></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login;