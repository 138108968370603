import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import MetisMenu from 'metismenujs';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'metismenujs/dist/metismenujs.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useHistory } from 'react-router-dom';

class MM extends Component {
    componentDidMount() {
        this.$el = this.el;
        this.mm = new MetisMenu(this.$el);
    }
    componentWillUnmount() {
        if (typeof (this.mm) === "function") {
            this.mm('dispose');
        }
        else { console.log('MetisMenu: ', this.mm) }
    }
    render() {
        return (
            <div className="mm-wrapper">
                <ul className="metismenu" ref={el => this.el = el}>
                    {this.props.children}
                </ul>
            </div>
        );
    }
}



class ReportsSidebar extends Component {

    componentDidMount() {

        // sidebar open/close
        var btn = document.querySelector('.report-sidebar-control');
        var aaa = document.querySelector('#reports-wrapper ');
        function toggleFunc() {
            // if (aaa.classList.toggle('menu-toggle2')){
            //    document.getElementById('sidebardiv').style.width = '0rem'

            // }
            // else
            // {
            //     document.getElementById('sidebardiv').style.width = '17.1875rem';
            // }
            return aaa.classList.toggle("menu-toggle2");

        }

        btn.addEventListener('click', toggleFunc);
        //
    }


    render() {


        return (

            <>

                <div className="deznav2" style={{zIndex: 1}}>
                    <PerfectScrollbar className="deznav2-scroll" >
                        <MM id="menu">

                            <li><Link className="has-arrow" to={''}  aria-expanded="false"><i
                                className="icon icon-single-04"></i><span className="nav-text">Konum Raporu</span></Link>
                                <ul aria-expanded="false">
                                    <li><Link to={'./test1'}>Geçmiş Konum Raporu</Link></li>
                                </ul>
                            </li>
                            <li><Link className="has-arrow" to={''} aria-expanded="false"><i
                                className="icon icon-single-04"></i><span className="nav-text">Hız Raporları</span></Link>
                                <ul aria-expanded="false">
                                    <li><Link to={'./test2'}>Hız Aşım Raporu</Link></li>
                                </ul>
                            </li>
                            <li/>


                        </MM>
                    </PerfectScrollbar>
                </div>

            </>
        );
    }
}

export default ReportsSidebar;