import React, { } from 'react';
import { useTable, useGlobalFilter, useFilters, useSortBy, useAsyncDebounce, usePagination } from "react-table";

import { Link } from 'react-router-dom';
import { Row, Col, Card, Modal } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';
import axios from "axios";
import Moment from 'react-moment';

import { matchSorter } from 'match-sorter'


function FleetManagement() {

    const [deleteModalShow, setDeleteModalShow] = React.useState(false);
    const [createApiModalShow, setCreateApiModalShow] = React.useState(false);
    const [newApi, setNewApi] = React.useState('');
    const [selectedApiId, setSelectedApiId] = React.useState('')
    const history = useHistory()
    const axios = require('axios');
    const [data, setData] = React.useState([])


    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    )

    const numberFormat = (value) =>
        new Intl.NumberFormat('tr-TR', {
            style: 'currency',
            currency: 'TRY'
        }).format(value);

    React.useEffect(() => {
        if (localStorage.getItem('loginToken') === null) {
            history.push('./login');
            return;
        }

    }, [])



    const columns = React.useMemo(
        () => [
            {
                Header: 'API ID',
                accessor: '_id',
                Cell: ({ value }) => { return <p>#{value}</p> },
                sortType: "number",

            },
            {
                Header: 'Seçenekler',

                Cell: (data) => {
                    //           const { value } = data;
                    //           if (!value) {
                    //             return <div>  
                    // <i  className="fa fa-trash-o btn btn-danger"  onClick={() => (setDeleteModalShow(true))} aria-hidden="true"></i>

                    //             </div>
                    //           }
                    return <a className={"fa fa-trash-o btn btn-danger"} href={"#"} onClick={() => { setSelectedApiId(data.row.original._id); setDeleteModalShow(true) }} />
                }
            },

        ],
        []
    )

    function GlobalFilter({
        preGlobalFilteredRows,
        globalFilter,
        setGlobalFilter,
    }) {
        const count = preGlobalFilteredRows.length
        const [value, setValue] = React.useState(globalFilter)
        const onChange = useAsyncDebounce(value => {
            setGlobalFilter(value || undefined)
        }, 200)

        return (
            <span>
                Ara:{' '}
                <input
                    value={value || ""}
                    onChange={e => {
                        setValue(e.target.value);
                        onChange(e.target.value);
                    }}
                    placeholder={`${count} kayıt içerisinde ara...`}
                    style={{
                        fontSize: '1.1rem',
                        border: '0',
                    }}
                />
            </span>
        )
    }

    // Define a default UI for filtering
    function DefaultColumnFilter({
        column: { filterValue, preFilteredRows, setFilter },
    }) {
        const count = preFilteredRows.length

        return (
            <input
                value={filterValue || ''}
                onChange={e => {
                    setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
                }}
                placeholder={`Search ${count} records...`}
            />
        )
    }

    function fuzzyTextFilterFn(rows, id, filterValue) {
        return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
    }

    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter(row => {
                    const rowValue = row.values[id]
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true
                })
            },
        }),
        []
    )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        state,
        visibleColumns,
        prepareRow,
        page,
        preGlobalFilteredRows,
        setGlobalFilter,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },

    } = useTable({
        columns,
        data: data,
        defaultColumn,
        filterTypes,
        initialState: {
            sortBy: [
                {
                    id: '_id',
                    desc: true
                }
            ], pageIndex: 0
        },

        // The rest of these things are super handy, too ;)


    }, useFilters,

        useGlobalFilter, useSortBy, usePagination)

    return (
    
        <>
            <div className='çontaíner-fluid text-white'>
            <Row>
                <Col xl={12}>
                    <Card>
                        <Card.Header>
                            <Card.Title>Filo Yönetimi</Card.Title>
                            {/* <button type="button" onClick={() => console.log} className="btn btn-success">API Anahtarı Oluştur</button> */}

                        </Card.Header>
                        <Card.Body>
                            <div className='container'>
                                {/* <pre>
                  <code>
                    {JSON.stringify(
                      {
                        pageIndex,
                        pageSize,
                        pageCount,
                        canNextPage,
                        canPreviousPage,
                      },
                      null,
                      2
                    )}
                  </code>
                </pre> */}
                                <table {...getTableProps()} className="table">
                                    <thead>
                                        {headerGroups.map(headerGroup => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map(column => (
                                                    <th
                                                        {...column.getHeaderProps(column.getSortByToggleProps)}
                                                    >

                                                        {column.render('Header')}
                                                        {column.isSorted
                                                            ? column.isSortedDesc
                                                                ? " (DESC)"
                                                                : " (ASC)"
                                                            : "(▼)"}

                                                    </th>
                                                ))}
                                            </tr>

                                        ))}
                                        <tr>
                                            <th
                                                colSpan={visibleColumns.length}
                                                style={{
                                                    textAlign: 'left',
                                                }}
                                            >
                                                <GlobalFilter
                                                    preGlobalFilteredRows={preGlobalFilteredRows}
                                                    globalFilter={state.globalFilter}
                                                    setGlobalFilter={setGlobalFilter}
                                                />
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody {...getTableBodyProps()}>
                                        {page.map((row, i) => {
                                            prepareRow(row)
                                            return (
                                                <tr {...row.getRowProps()}>
                                                    {row.cells.map(cell => {
                                                        return (
                                                            <td
                                                                {...cell.getCellProps()}
                                                            >
                                                                {cell.render('Cell')}
                                                            </td>
                                                        )
                                                    })}
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}

                                <div className="pagination">

                                    <ul className="pagination">
                                        <li className="page-item">
                                            <a href="#" className="page-link" onClick={() => gotoPage(0)} disabled={!canPreviousPage} aria-label="Previous">
                                                <span aria-hidden="true">&laquo;</span>
                                                <span className="sr-only">Previous</span>
                                            </a>
                                        </li>

                                        {Array.apply(0, Array(pageOptions.length)).map((page, i) =>

                                            <li key={i} className={pageIndex === i ? "page-item active" : "page-item"}>
                                                {pageIndex === i ?
                                                    <a className="page-link" onClick={() => gotoPage(i)} href="#">{i + 1}</a>
                                                    : <a className="page-link" onClick={() => gotoPage(i)} href="#">{i + 1}</a>
                                                }
                                            </li>

                                        )}

                                        <li className="page-item">
                                            <a href="#" className="page-link" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} aria-label="Next">
                                                <span aria-hidden="true">&raquo;</span>
                                                <span className="sr-only">Next</span>
                                            </a>
                                        </li>
                                    </ul>


                                    {/* <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>{' '} */}
                                    {/* <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <span>
          | Go to page:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              gotoPage(page)
            }}
            style={{ width: '100px' }}
          />
        </span>{' '} */}

                                    <select
                                        style={{
                                            boxSizing: 'border-box', borderRadius: 'inherit', height: '40px'
                                        }}
                                        value={pageSize}
                                        onChange={e => {
                                            setPageSize(Number(e.target.value))
                                        }}
                                    >
                                        {[10, 20, 30, 40, 50].map(pageSize => (
                                            <option key={pageSize} style={{  // A reset of styles, including removing the default dropdown arrow
                                                appearance: 'none',
                                                // Additional resets for further consistency
                                                backgroundColor: 'transparent',
                                                border: 'none',
                                                padding: '0 1em 0 0',
                                                margin: 0,
                                                width: '100%',
                                                fontFamily: 'inherit',
                                                fontSize: 'inherit',
                                                cursor: 'inherit',
                                                lineHeight: 'inherit'
                                            }} value={pageSize}>
                                                Show {pageSize}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Modal
                size="sm"
                show={deleteModalShow}
                onHide={() => setDeleteModalShow(false)}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        Uyarı
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body><b><i className="fa fa-lock"></i> &nbsp;:&nbsp;

                    {selectedApiId}</b>
                    <p>Yukarıdaki API Anahtarını silmek istediğinize emin misiniz ?</p></Modal.Body>
                <div className="modal-footer"><button onClick={() => setDeleteModalShow(false)} type="button" className="btn btn-secondary">İptal</button>
                    <button type="button" onClick={() => console.log("delete")} className="btn btn-danger">Sil</button>
                    </div>
            </Modal>

            <Modal
                size="sm"
                show={createApiModalShow}
                onHide={() => setCreateApiModalShow(false)}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                {!newApi.keyId ? 
                <>
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-sm">
                        Başarısız
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{newApi}</p></Modal.Body>

                <div className="modal-footer"><button onClick={() => setCreateApiModalShow(false)} type="button" className="btn btn-secondary">Tamam</button>
                   </div>
                   </> : 
                   <>
                   <Modal.Header closeButton>
                       <Modal.Title id="example-modal-sizes-title-sm">
                           Başarılı
                       </Modal.Title>
                   </Modal.Header>
                   <Modal.Body><b><i className="fa fa-lock"></i> &nbsp;:&nbsp;
   
                       {newApi.keyId}</b>
                       <p>Yeni API Anahtarınız başarıyla oluşturuldu. Lütfen API Anahtarınızı indirin ve saklayın.</p></Modal.Body>
   
                   <div className="modal-footer"><button onClick={() => setCreateApiModalShow(false)} type="button" className="btn btn-secondary">İptal</button>
                       <button type="button" onClick={() => console.log('indirilecek')} className="btn btn-success">İndir</button></div>
                      </> }
            </Modal>
            </div>
        </>

    )
}

export default FleetManagement;