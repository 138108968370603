import React from 'react';
import { Dropdown } from 'react-bootstrap'

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={e => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </a>
));


function HeaderNotification() {


    return (
        <>
            <Dropdown>
                <Dropdown.Toggle as={CustomToggle}>
                    <i className="fa fa-bell"></i>
                    <div className="pulse-css"></div>
                </Dropdown.Toggle>

                <Dropdown.Menu className="dropdown-menu-right" alignRight>
                    <ul className="list-unstyled">
                        <li className="media dropdown-item">
                            <span className="danger"><i className="ti-alert"></i></span>
                            <div className="media-body">
                                <a href="/phone-verification">
                                    <p>Telefon numaranız <strong>onaylanmadı</strong> 
                                                    </p>
                                </a>
                            </div>
                            <span className="notify-time">3:20 am</span>
                        </li>
                        <li className="media dropdown-item">
                            <span className="primary"><i className="ti-shopping-cart"></i></span>
                            <div className="media-body">
                                <a href="#">
                                    <p><strong>Jennifer</strong> purchased Light Dashboard 2.0.</p>
                                </a>
                            </div>
                            <span className="notify-time">3:20 am</span>
                        </li>
                        <li className="media dropdown-item">
                            <span className="danger"><i className="ti-bookmark"></i></span>
                            <div className="media-body">
                                <a href="#">
                                    <p><strong>Robin</strong> marked a <strong>ticket</strong> as unsolved.
                                                    </p>
                                </a>
                            </div>
                            <span className="notify-time">3:20 am</span>
                        </li>
                        <li className="media dropdown-item">
                            <span className="primary"><i className="ti-heart"></i></span>
                            <div className="media-body">
                                <a href="#">
                                    <p><strong>David</strong> purchased Light Dashboard 1.0.</p>
                                </a>
                            </div>
                            <span className="notify-time">3:20 am</span>
                        </li>
                        <li className="media dropdown-item">
                            <span className="success"><i className="ti-image"></i></span>
                            <div className="media-body">
                                <a href="#">
                                    <p><strong> James.</strong> has added a<strong>customer</strong> Successfully
                                                    </p>
                                </a>
                            </div>
                            <span className="notify-time">3:20 am</span>
                        </li>
                    </ul>
                    <a className="all-notification" href="#">See all notifications <i
                        className="ti-arrow-right"></i></a>

                </Dropdown.Menu>
            </Dropdown>
        </>
    );
}

export default HeaderNotification;


