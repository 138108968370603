import React, { Component } from 'react';
import Usa from "@svg-maps/usa";
import { SVGMap } from "react-svg-map";
import "react-svg-map/lib/index.css";

const svgProps = {
		svgUrl: "svg",
		idColorMap: {
			"*": "yellow"
		},
		onPrimitiveClick: (id) => console.log(id),
	};


function UsaMap() {

    return (
        <>
            <SVGMap 
				map={Usa}  
				className={'svg-map'}
				/* backgroundColor={'#000'}
				height={ 1000 }
				color= "rgb(239, 242, 244)"
				{...svgProps} */
			/>
        </>
    )
}

export default UsaMap;