import React from 'react'
import { Card, Row,Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const RegisterationSuccess = props => {
    const { email } = (props.location && props.location.state) || {};

    return (
        <>
        <Row className='mt-5' style={{justifyContent:'center'}}>
          <Col xl={8}>
            <Card>
      
              <Card.Body>
              <div className="row justify-content-center mt-5 align-items-center error-page">
                <div className="col-md-6">
                    <div className="form-input-content text-center">
                    
                        <h4 className="mt-4"><i className="fa fa-check text-success"></i> </h4>
                        <h2>Lütfen hesabınızı doğrulayın</h2> 
                        <p>Kayıt olduğunuz için teşekkür ederiz!</p> 
                        <p><span className="bold"><b> {email} </b></span> adresine bir doğrulama e-postası gönderdik.</p> 
                        <p>Adresinizi onaylamak için lütfen e-postadaki bağlantıyı tıklayın (filtrelenmiş olması durumunda spam klasörünüzü kontrol edin).</p>
                        <Link className="btn btn-primary" to={'./login'}>Hesabımı Doğruladım</Link>

                    </div>
                </div>
            </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </>

    )
}
export default RegisterationSuccess;
