import * as actionTypes from "../actions/actionTypes"
import initialState from "./initialState"

export default function changeCardReducer(state=initialState.currentCard, action) {//State, Action

    switch (action.type) {
        case actionTypes.CHANGE_SELECTED_CARD:
            return action.payload
        
        default:
            return state;
    }
}