import axios, { AxiosResponse } from 'axios';
import React from 'react';
import { GetAllResponce, RemoteDevice } from '../types/devices';
import { useLogin } from './loginProvider';

enum DeviceProviderStatus {
  Waiting,
  Loading,
  Loaded,
  Errored,
}

type DeviceContextType = {
  devices: RemoteDevice[];
  focusedDevice: RemoteDevice | null;
  setFocusedDevice: (device: RemoteDevice | null) => void;
  status: DeviceProviderStatus;
};

const LoginContext = React.createContext<DeviceContextType>({
  devices: [],
  status: DeviceProviderStatus.Waiting,
  focusedDevice: null,
  setFocusedDevice: () => {},
});

export function DeviceProvider({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) {
  const [status, setStatus] = React.useState<DeviceProviderStatus>(
    DeviceProviderStatus.Waiting,
  );
  const [devices, setDevices] = React.useState<RemoteDevice[]>([]);

  const [timer, setTimer] = React.useState<NodeJS.Timer | null>(null);
  const [focusedDevice, setFocusedDevice] = React.useState<RemoteDevice | null>(
    null,
  );
  const { loginState } = useLogin();
  React.useEffect(() => {
    console.log('Start Devices');
    if (loginState) {
      console.log('deviceProvider loginstate ')
      setStatus(DeviceProviderStatus.Loading);
      update();
    } else {
      setDevices([]);
      setStatus(DeviceProviderStatus.Waiting);
    }
  }, [loginState]);

  React.useEffect(() => {
    if (status === DeviceProviderStatus.Loaded) {
      console.log('Start Looping');
      setTimer(
        setInterval(() => {
          update();
        }, 10000),
      );
    } else {
      if (timer) {
        clearInterval(timer);
      }
      console.log('Stop Timer', status);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [status]);

  React.useEffect(() => {
    if (focusedDevice) {
      const newFocus = devices.find(d => d.Id === focusedDevice.Id) ?? null;
      setFocusedDevice(newFocus);
    }
  }, [devices]);
  
  function update() {

    console.log('====================',loginState)
    axios.get<GetAllResponce>('https://api.ircom.com.tr/api/arac/getall',{    headers: {
      'Authorization': `Bearer ${loginState!}`
    }})

    .then(response => response)
    .then((result) => {
      setStatus(DeviceProviderStatus.Loaded);
      setDevices(result.data.Data);
    }).catch(error => {
      console.error('error', error);
      // alert(error);
    })
    const myHeaders: any = {};
    myHeaders.Authorization = `Bearer ${loginState}`;
    myHeaders['Content-Type'] = 'application/json';

    // fetch('https://api.ircom.com.tr/api/arac/getall', {
    //   method: 'GET',
    //   headers: myHeaders,
    //   redirect: 'manual',
    // })
    //   .then(response => response.json())
    //   .then((result: GetAllResponce) => {
    //     setStatus(DeviceProviderStatus.Loaded);
    //     setDevices(result.Data);
    //   })
    //   .catch(error => {
    //     // if(timer) {
    //     //     clearInterval(timer);
    //     // }
    //     console.error('error', error);
    //     // setLoginToken(null);
    //     alert('Hata!');
    //   });
  }

  return (
    <>
      {/* <AnimatedLoader
        visible={status === DeviceProviderStatus.Loading}
        overlayColor="rgba(255,255,255,0.75)"
        source={require('../../assets/animations/29476-locate-gps.json')}
        animationStyle={{
          width: 200,
          height: 200,
        }}
        speed={1}
      /> */}
      <LoginContext.Provider
        value={{ devices, status, focusedDevice, setFocusedDevice }}
      >
        {children}
      </LoginContext.Provider>
    </>
  );
}

export const useDevices = () => React.useContext(LoginContext);
